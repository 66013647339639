import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[16px]" }
const _hoisted_2 = { class: "flex gap-[8px] text-[14px] leading-[22px] font-medium" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_FormLabel = _resolveComponent("FormLabel")
  const _component_el_input = _resolveComponent("el-input")
  const _component_CommunityMDTextarea = _resolveComponent("CommunityMDTextarea")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgIcon, {
        height: "24px",
        weight: "24px",
        name: "discussions"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('community.newDiscussion.new')), 1 /* TEXT */)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_FormLabel, {
        labelName: this.$t('community.newDiscussion.title'),
        required: true
      }, null, 8 /* PROPS */, ["labelName"]),
      _createVNode(_component_el_input, {
        modelValue: $data.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.title) = $event)),
        maxLength: 200,
        "show-word-limit": "",
        clearable: "",
        placeholder: this.$t('community.newDiscussion.create'),
        class: "w-full h-[40px] text-[#606266]"
      }, {
        suffix: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString($data.title.length) + " / 200", 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "placeholder"])
    ]),
    _createVNode(_component_FormLabel, {
      labelName: this.$t('community.newDiscussion.content'),
      required: true
    }, null, 8 /* PROPS */, ["labelName"]),
    _createVNode(_component_CommunityMDTextarea, {
      desc: $data.desc,
      onInputChange: $options.handleInputChange
    }, null, 8 /* PROPS */, ["desc", "onInputChange"]),
    _createElementVNode("div", null, [
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: $options.create
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('community.newDiscussion.create')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_el_button, { onClick: $options.cancel }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('community.newDiscussion.cancel')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ])
  ]))
}