import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex justify-end w-full" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "dataFormRef",
    model: $setup.dataForm,
    rules: $setup.rules,
    class: "w-full flex flex-col gap-[14px]",
    "label-position": "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "Source Url",
        prop: "source_url",
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: $setup.dataForm.source_url,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.dataForm.source_url) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Mirror Source Id",
        prop: "mirror_source_id",
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: $setup.dataForm.mirror_source_id,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.dataForm.mirror_source_id) = $event)),
            placeholder: "请选择"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selectSourcesOptions, (source) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  label: source.source_name,
                  value: source.id
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Username",
        prop: "username",
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: $setup.dataForm.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.dataForm.password) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Accese Token",
        prop: "password",
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: $setup.dataForm.acceseToken,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.dataForm.acceseToken) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Mirrorable Type",
        porp: "mirrorableType",
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: $setup.dataForm.mirrorableType,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.dataForm.mirrorableType) = $event)),
            placeholder: "请选择"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "Model",
                value: "models"
              }),
              _createVNode(_component_el_option, {
                label: "Dataset",
                value: "datasets"
              }),
              _createVNode(_component_el_option, {
                label: "Code",
                value: "codes"
              }),
              _createVNode(_component_el_option, {
                label: "Space",
                value: "spaces"
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Repo Path",
        prop: "repoPath",
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: $setup.dataForm.repoPath,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($setup.dataForm.repoPath) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, { class: "w-full" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: $setup.handleSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 提交 ")
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model"]))
}