import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd21096f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-[77px] w-full p-4 bg-white border-b border-[#eaecf0] items-center gap-2 inline-flex rounded-tr-2xl" }
const _hoisted_2 = { class: "text-[#344053] text-base font-medium leading-normal" }
const _hoisted_3 = { class: "p-4" }
const _hoisted_4 = { class: "text-[#344053] text-sm font-medium leading-tight mb-4 flex items-center gap-2" }
const _hoisted_5 = { class: "min-h-[180px] px-3.5 py-3 bg-white rounded-lg shadow border border-[#cfd4dc] text-[#0f1728] text-base font-light leading-normal mb-4 overflow-auto" }
const _hoisted_6 = { class: "flex mt-[8px]" }
const _hoisted_7 = { class: "ml-[4px] text-[12px] leading-[18px] text-[#667085]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_input = _resolveComponent("el-input")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SvgIcon, { name: "playground_test" }),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('endpoints.playground.test')), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SvgIcon, { name: "text_generation" }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('endpoints.playground.generation')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["MarkdownViewer"], { content: $setup.anwserContent }, null, 8 /* PROPS */, ["content"])
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex items-center justify-between p-3 gap-2 rounded-lg shadow border relative", 
        $setup.inputFocus
          ? 'border-[#6483f8] [box-shadow:rgba(16,_24,_40,_0.05)_0px_1px_2px,_rgba(77,_106,_214,_0.24)_0px_0px_0px_4px]'
          : 'border-[#cfd4dc]'
      ])
      }, [
        _createVNode(_component_el_input, {
          modelValue: $setup.message,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.message) = $event)),
          inputStyle: "outline: none",
          onFocus: $setup.handleFocus,
          onBlur: $setup.handleBlur,
          onKeydown: _withKeys($setup.handleEnterPress, ["enter"]),
          onCompositionend: $setup.compositionEnd,
          onCompositionstart: $setup.compositionStart
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", {
          class: _normalizeClass(["h-[34px] px-3 py-2 rounded-lg shadow border border-[#eaecf0] justify-center items-center gap-1 inline-flex flex-shrink-0", 
          $setup.canSendMessage
            ? 'bg-[#3250bd] cursor-pointer'
            : 'bg-[#f2f3f6] cursor-not-allowed'
        ]),
          onClick: $setup.handleSendMessage
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["text-xs font-normal flex items-center justify-center gap-1", $setup.canSendMessage ? 'text-[#fff]' : 'text-[#98a1b2]'])
          }, [
            _createVNode(_component_SvgIcon, { name: "send_message" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('endpoints.playground.send')), 1 /* TEXT */)
          ], 2 /* CLASS */)
        ], 2 /* CLASS */)
      ], 2 /* CLASS */)), [
        [_directive_loading, $setup.loading]
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_SvgIcon, {
          name: "exclamation_point",
          class: "place-self-start"
        }),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('widgets.liabilityExemption')), 1 /* TEXT */)
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}