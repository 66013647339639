import { rule } from './zh_js/rule';
import { models } from './zh_js/models';
import { comment } from './zh_js/comment';
import { datasets } from './zh_js/datasets';
import { organization } from './zh_js/organization';
import { navbar } from './zh_js/navbar';
import { all } from './zh_js/all';
import { community } from './zh_js/community';
import { profile } from './zh_js/profile';
import { accessToken } from './zh_js/accessToken';
import { sshKey } from './zh_js/sshKey';
import { shared } from './zh_js/shared';
import { repo } from './zh_js/repo';
import { application_spaces } from './zh_js/application_spaces';
import { codes } from './zh_js/codes';
import { widgets } from './zh_js/widgets';
import { spaces } from './zh_js/spaces';
import { user_sessions } from './zh_js/user_sessions';
import { collections } from './zh_js/collections';
import { components } from './zh_js/components';
import { space } from './zh_js/space';
import { campaign } from './zh_js/campaign';
import { computing } from './zh_js/computing';
import { product } from './zh_js/product';
import { solution } from './zh_js/solution';
import { dailyPaper } from './zh_js/dailyPaper';
import { form } from './zh_js/form';
import { landingPage } from './zh_js/landing_page';
import { footer } from './zh_js/footer';
import { ekb } from './zh_js/ekb';
import { partner } from './zh_js/partner';
import { endpoints } from './zh_js/endpoints';
import { csghub } from './zh_js/csghub';
import { finetune } from './zh_js/finetune';
import { admin } from './zh_js/admin';
import { starShip } from './zh_js/star_ship';
import { new_admin } from './zh_js/new_admin';
import { billing } from './zh_js/billing';
import { starShipIde } from './zh_js/starshipIde';
import { eaiArticle } from './zh_js/eai_articles';
import { resourceConsole } from './zh_js/resource_console';
import { tags } from './zh_js/tags';
import { languages } from './zh_js/languages';
import { dataflow } from './zh_js/dataflow';

export default {
  // csghub
  all,
  rule,
  user_sessions,
  spaces,
  application_spaces,
  widgets,
  models,
  comment,
  datasets,
  codes,
  organization,
  navbar,
  community,
  profile,
  accessToken,
  sshKey,
  shared,
  repo,
  collections,
  components,
  endpoints,
  admin,
  // portal
  space,
  campaign,
  computing,
  product,
  solution,
  dailyPaper,
  form,
  landingPage,
  footer,
  ekb,
  partner,
  csghub,
  starShip,
  new_admin,
  billing,
  finetune,
  starShipIde,
  eaiArticle,
  resourceConsole,
  tags,
  languages,
  dataflow
};
