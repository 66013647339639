<template>
  <div class="max-w-[841px] w-full flex h-[200px] md:flex-col md:h-[unset] md:max-w-[unset] md:bg-[var(--Gray-25)] md:border md:border-[#EAECF0] md:rounded-[8px]">
    <a :href="device.link" target="_blank">
      <img :src="device.cover_url" class="w-[405px] md:w-full h-[200px] rounded-[16px_0px_0px_16px] md:rounded-0 object-cover">
    </a>
    <div class="w-full flex flex-col justify-between p-[32px]">
      <a :href="device.link" target="_blank">
        <div class="flex flex-col gap-[8px] justify-between">
          <div class="text-[var(--Gray-900)] text-[18px] font-[500] leading-[28px] line-clamp line-clamp-1">{{ device.title }}</div>
          <div class="text-[var(--Gray-600)] text-[16px] font-[300] leading-[24px] line-clamp line-clamp-2">{{ device.description }}</div>
        </div>
      </a>
      <a :href="device.link" target="_blank" class="flex items-center md:hidden">
        <div class="text-[var(--Gray-500)] text-[14px] font-[400] leading-[20px] mr-[6px]">
          {{ t('eaiArticle.learnMore') }}
        </div>
        <div class="flex items-center">
          <el-icon><ArrowRight :size="14"/></el-icon>
        </div>
      </a>
    </div>
  </div>
</template>
<script setup>
  import { ref } from "vue";
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()

  const props = defineProps({
    device: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        cover: "",
        view_count: 0,
        content: "",
      }),
    }
  });

  const device = ref(props.device);
</script>