<template>
  <div class="flex flex-col justify-center bg-white">

    <!-- banner -->
    <div class="text-center w-full bg-top bg-no-repeat bg-[url('images/landing/BackgroundPattern.png')] py-[96px] md:py-[48px] md:px-[16px]">
      <div class="sm:w-full max-w-[1280px] m-auto">
        <span class="text-[16px] md:text-[14px] font-[500] leading-[24px] md:leading-[22px] text-[var(--Gray-600)] mb-[12px] md:mb-[8px]">DataFlow</span>
        <div
          class="relative mb-[24px] md:mb-[12px] tracking-[-1.2px] font-[500] md:tracking-[-0.72px] m-auto text-[72px] leading-[90px] md:text-[28px] md:leading-[38px] text-[#101828] "
        >
          <span class="relative z-10">
            {{ $t('dataflow.banner.title') }}
          </span>
        </div>
        <div class="text-[20px] md:text-[14px] text-[var(--Gray-600)] leading-[30px] font-[400] md:leading-[20px] mb-[48px] md:mb-[32px] max-w-[634px] md:max-w-[310px] m-auto">
          {{ $t('dataflow.banner.subtitle') }}
        </div>
        <a
          v-if="!isLoggedIn"
          href="/login"
          class="btn btn-primary inline-block px-[22px] md:px-[12px] py-[16px] md:py-[8px]"
        >
          <span class="md:text:12px md:leading-[18px]">{{ $t('dataflow.banner.reserveDemo') }}</span>
        </a>
      </div>
    </div>

    <div class="max-w-[1440px] m-auto mt-[-41px]">
      <img src="images/dataflow/placeholder.png" alt="dataflow-banner" class="w-full md:hidden" />
      <img src="images/dataflow/placeholder_mobile.png" alt="dataflow-banner" class="hidden md:block md:w-full md:px-[20px]" />
    </div>

    <!-- feature -->
    <div class="flex flex-col gap-[var(--spacing-9xl)] mt-[60px] max-w-[1320px] w-full px-[20px] md:px-[unset] m-auto bg-[var(--Base-White)]">
      <template v-for="item in data" :key="item.title">
        <div class="w-full flex gap-[116px] md:gap-[40px] justify-between md:flex-col">
          <div :class="`max-w-[460px] flex flex-col gap-[24px] w-full md:px-[20px] ${item.position === 'left' ? 'order-1 md:order-1' : 'order-2 md:order-1'}`">
            <div class="bg-[var(--Brand-600)] rounded-[10px] w-[48px] h-[48px] md:w-[40px] md:h-[40px] p-[12px] md:p-[10px]">
              <SvgIcon path="dataflow" :name="item.icon" class="md:w-[20px] md:h-[20px]" />
            </div>
            <div class="text-[30px] md:text-[20px] font-[500] leading-[38px] md:leading-[30px] text-[var(--Gray-900)]">
              {{ item.content.title }}
            </div>
            <div class="flex flex-col gap-[20px]">
              <template v-for="feature in item.content.features" :key="feature.title">
                <div class="flex flex-col gap-[8px]">
                  <div class="flex gap-[12px]">
                    <SvgIcon path="dataflow" name="check-circle" width="28" height="28" />
                    <span class="text-[20px] md:text-[18px] font-[500] leading-[30px] md:leading-[28px] text-[var(--Gray-700)]">{{ feature.title }}</span>
                  </div>
                  <div class="text-[18px] md:text-[16px] font-[400] leading-[28px] md:leading-[24px] text-[var(--Gray-600)]">
                    {{ feature.description }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div :class="`w-full md:px-[20px] ${item.position === 'left' ? 'order-2 md:order-2' : 'order-1 md:order-2'}`" :style="{ 'max-width': `${item.imageMaxWidth} !important` }" >
            <img :src="item.image" alt="dataflow-feature" class="w-full" />
          </div>
        </div>
      </template>
    </div>

    <!-- 产品优势 -->
    <div class="mt-[var(--spacing-9xl)] md:mt-[60px] py-[var(--spacing-9xl)] md:py-[var(--spacing-6xl)] w-full m-auto bg-[var(--Gray-50)]">
      <div class="flex flex-col items-center gap-[48px] md:gap-[var(--spacing-3xl)] max-w-[1280px] m-auto md:px-[20px] md:max-w-full w-full">
        <div class="text-[36px] md:text-[24px] font-[500] leading-[44px] md:leading-[32px] text-[var(--Gray-900)] tracking-[-0.72px]">
          {{ $t('dataflow.productAdvantage.title') }}
        </div>
        <div class="flex md:flex-col gap-[var(--spacing-4xl)] md:gap-[20px] justify-between">
          <template v-for="item in productAdvantageData" :key="item.title">
            <div class="flex flex-col gap-[var(--spacing-4xl)] p-[var(--spacing-3xl)] bg-white rounded-[8px] max-w-[296px] w-full md:max-w-full">
              <div class="bg-[var(--Brand-600)] rounded-[10px] w-[48px] h-[48px] md:w-[40px] md:h-[40px] p-[12px] md:p-[10px]">
                <SvgIcon path="dataflow" :name="item.icon" class="md:w-[20px] md:h-[20px]" />
              </div>
              <div class="flex flex-col gap-[8px]">
                <div class="text-[20px] md:text-[18px] font-[500] leading-[30px] md:leading-[28px] text-[var(--Gray-900)]">
                  {{ item.title }}
                </div>
                <div class="text-[16px] font-[400] md:font-[300] leading-[24px] text-[var(--Gray-600)]">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()

  const data = [
    {
      position: 'left',
      content: {
        title: t('dataflow.feature.oneStopDataProcessing.title'),
        features: [
          {
            title: t('dataflow.feature.oneStopDataProcessing.fullProcessCoverage.title'),
            description: t('dataflow.feature.oneStopDataProcessing.fullProcessCoverage.description'),
          },
          {
            title: t('dataflow.feature.oneStopDataProcessing.closedLoopManagement.title'),
            description: t('dataflow.feature.oneStopDataProcessing.closedLoopManagement.description'),
          },
          {
            title: t('dataflow.feature.oneStopDataProcessing.intelligentOperation.title'),
            description: t('dataflow.feature.oneStopDataProcessing.intelligentOperation.description'),
          }
        ]
      },
      icon: "oneStopDataProcessing_icon",
      image: "images/dataflow/oneStopDataProcessing.svg",
      imageMaxWidth: "672px",
    },
    {
      position: 'right',
      content: {
        title: t('dataflow.feature.multiFormatAndMultiSourceDataCompatibility.title'),
        features: [
          {
            title: t('dataflow.feature.multiFormatAndMultiSourceDataCompatibility.multiDataSourceCompatibility.title'),
            description: t('dataflow.feature.multiFormatAndMultiSourceDataCompatibility.multiDataSourceCompatibility.description'),
          },
          {
            title: t('dataflow.feature.multiFormatAndMultiSourceDataCompatibility.wideDataFormat.title'),
            description: t('dataflow.feature.multiFormatAndMultiSourceDataCompatibility.wideDataFormat.description'),
          },
          {
            title: t('dataflow.feature.multiFormatAndMultiSourceDataCompatibility.flexibleConversionTool.title'),
            description: t('dataflow.feature.multiFormatAndMultiSourceDataCompatibility.flexibleConversionTool.description'),
          }
        ]
      },
      icon: "multiFormatAndMultiSourceDataCompatibility_icon",
      image: "images/dataflow/multiFormatAndMultiSourceDataCompatibility.svg",
      imageMaxWidth: "668px",
    },
    {
      position: 'left',
      content: {
        title: t('dataflow.feature.dataCleaningAndConversion.title'),
        features: [
          {
            title: t('dataflow.feature.dataCleaningAndConversion.efficientCleaningEngine.title'),
            description: t('dataflow.feature.dataCleaningAndConversion.efficientCleaningEngine.description'),
          },
          {
            title: t('dataflow.feature.dataCleaningAndConversion.complexConversionSupport.title'),
            description: t('dataflow.feature.dataCleaningAndConversion.complexConversionSupport.description'),
          },
          {
            title: t('dataflow.feature.dataCleaningAndConversion.multiThreadParallelProcessing.title'),
            description: t('dataflow.feature.dataCleaningAndConversion.multiThreadParallelProcessing.description'),
          }
        ]
      },
      icon: "dataCleaningAndConversion_icon",
      image: "images/dataflow/dataCleaningAndConversion.svg",
      imageMaxWidth: "672px",
    },
    {
      position: 'right',
      content: {
        title: t('dataflow.feature.intelligentAnnotationSystem.title'),
        features: [
          {
            title: t('dataflow.feature.intelligentAnnotationSystem.collaborativeAnnotation.title'),
            description: t('dataflow.feature.intelligentAnnotationSystem.collaborativeAnnotation.description'),
          },
          {
            title: t('dataflow.feature.intelligentAnnotationSystem.permissionAndAuditMechanism.title'),
            description: t('dataflow.feature.intelligentAnnotationSystem.permissionAndAuditMechanism.description'),
          },
          {
            title: t('dataflow.feature.intelligentAnnotationSystem.realTimeProgressMonitoring.title'),
            description: t('dataflow.feature.intelligentAnnotationSystem.realTimeProgressMonitoring.description'),
          }
        ]
      },
      icon: "intelligentAnnotationSystem_icon",
      image: "images/dataflow/intelligentAnnotationSystem.svg",
      imageMaxWidth: "668px",
    }
  ]

  const productAdvantageData = [
    {
      title: t('dataflow.productAdvantage.fullLifeCycleManagement.title'),
      description: t('dataflow.productAdvantage.fullLifeCycleManagement.description'),
      icon: "fullLifeCycleManagement_icon",
    },
    {
      title: t('dataflow.productAdvantage.modularDesignAndFlexibleExtension.title'),
      description: t('dataflow.productAdvantage.modularDesignAndFlexibleExtension.description'),
      icon: "modularDesignAndFlexibleExtension_icon",
    },
    {
      title: t('dataflow.productAdvantage.distributedComputingSupport.title'),
      description: t('dataflow.productAdvantage.distributedComputingSupport.description'),
      icon: "distributedComputingSupport_icon",
    },
    {
      title: t('dataflow.productAdvantage.hyperparameterOptimization.title'),
      description: t('dataflow.productAdvantage.hyperparameterOptimization.description'),
      icon: "hyperparameterOptimization_icon",
    }
  ]
</script>