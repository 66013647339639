<template>
  <div class="w-full border border-1 border-[#EAECF0] rounded-[16px] p-[20px]">
    <a :href="`/codes/${code.path}`">
      <div class="flex flex-col gap-[8px] justify-between h-full">
        <div class="flex gap-[8px]">
          <el-avatar :size="24" :src="code.namespace.Avatar">
          </el-avatar>
          <div class="text-[var(--Gray-700)] text-[14px] font-[500] leading-[20px] md:text-[16px] md:leading-[24px]">
            {{ code.path }}
          </div>
        </div>
  
        <div class="text-[var(--Gray-500)] text-[14px] font-[400] leading-[20px] line-clamp line-clamp-1">
          {{ code.description }}
        </div>
  
        <div class="flex gap-[12px] items-center text-[var(--Gray-500)] text-[12px] font-[400] leading-[18px]">
          <!-- <div class="flex gap-[4px] items-center">
            <div :class="`w-[12px] h-[12px] rounded-[50px] ${getSourceTheme(code.source)}`"></div>
            <div>{{ code.source }}</div>
          </div>
          <div class="">|</div> -->
          <div class="flex gap-[4px] items-center">
            <SvgIcon name="clock" width="12" height="12" />
            <div>{{ t('eaiArticle.updatedAt') }}:</div>
            <div>{{ dayjs(code.created_at).fromNow() }}</div>
          </div>
          <div class="">|</div>
          <div class="flex gap-[4px] items-center">
            <SvgIcon name="download2" width="12" height="12" />
            <div>{{ code.downloads }}</div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
<script setup>
  import { ref } from "vue";
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  import dayjs from 'dayjs'
  import relativeTime from 'dayjs/plugin/relativeTime'
  import { stringMod } from '../../packs/utils'

  dayjs.extend(relativeTime)

  const props = defineProps({
    code: {
      type: Object,
      default: () => ({}),
    }
  });

  const code = ref(props.code);

  const themesMap = {
    0: 'bg-[#3572A5]',
    1: 'bg-[#E34C26]',
    2: 'bg-[#F34B7D]',
    3: 'bg-[#1572B6]',
    4: 'bg-[#2B7489]',
    5: 'bg-[#E34C26]',
    6: 'bg-[#F1E05A]',
  }

  const getSourceTheme = (source) => {
    const mod = stringMod(source, 6);
    return themesMap[mod];
  };
</script>