import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-6" }
const _hoisted_2 = { class: "w-[294px] rounded-[8px] mx-[24px]" }
const _hoisted_3 = { class: "ml-[10px]" }
const _hoisted_4 = { class: "text-[18px] leading-[28px] font-semibold" }
const _hoisted_5 = { class: "text-[16px] text-[#909399] leading-[24px] font-light" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "flex justify-center flex-col m-auto w-full relative" }
const _hoisted_8 = { class: "dialog-footer flex justify-between" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          onClick: $setup.clickProfile,
          class: "flex p-[16px] cursor-pointer"
        }, [
          _createVNode(_component_el_avatar, {
            size: 60,
            src: $setup.userStore.avatar
          }, null, 8 /* PROPS */, ["src"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString($setup.userStore.nickname || $setup.userStore.username), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_5, "@" + _toDisplayString($setup.userStore.username), 1 /* TEXT */)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createCommentVNode(" profile "),
          _createElementVNode("a", {
            href: "/settings/profile",
            class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/profile')])
          }, _toDisplayString(_ctx.$t('profile.menu.profile')), 3 /* TEXT, CLASS */),
          _createCommentVNode(" <div class=\"p-[16px] hover:bg-[#EBEDF0] border-b border-[#DCDFE6] text-[18px] text-[#606266] leading-[26px] opacity-40\"\n               :class=\"menuClass('/settings/account')\"\n          >\n            {{ $t('profile.menu.accountInformation')}}\n          </div> "),
          _createCommentVNode(" <div class=\"p-[16px] hover:bg-[#EBEDF0] border-b border-[#DCDFE6] text-[18px] text-[#606266] leading-[26px] opacity-40\"\n               :class=\"menuClass('/settings/accessTokens')\"\n          >\n            {{ $t('profile.menu.accessToken')}}\n          </div> "),
          _createCommentVNode(" access token "),
          ($setup.hasEmail)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: "/settings/access-token",
                class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/access-token')])
              }, _toDisplayString(_ctx.$t('profile.menu.gitToken')), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" starship api key "),
          ($setup.hasEmail)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: "/settings/starship-access-token",
                class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/starship-access-token')])
              }, _toDisplayString(_ctx.$t('profile.menu.starshipAccessToken')), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" sync access token "),
          ($setup.hasEmail)
            ? (_openBlock(), _createElementBlock("a", {
                key: 2,
                href: "/settings/sync-access-token",
                class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/sync-access-token')])
              }, _toDisplayString(_ctx.$t('profile.menu.syncAccessToken')), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" ssh key "),
          ($setup.hasEmail)
            ? (_openBlock(), _createElementBlock("a", {
                key: 3,
                href: "/settings/ssh-keys",
                class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/ssh-keys')])
              }, _toDisplayString(_ctx.$t('profile.menu.sshKey')), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true),
          ($setup.hasEmail)
            ? (_openBlock(), _createElementBlock("a", {
                key: 4,
                href: "/settings/billing",
                class: _normalizeClass(["p-[16px] hover:bg-[#F9FAFB] border-[#DCDFE6] text-[16px] text-[#606266] leading-[24px] cursor-pointer", $setup.menuClass('/settings/billing')])
              }, _toDisplayString(_ctx.$t('profile.menu.billing')), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.showDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.showDialog) = $event)),
      width: "350",
      style: { borderRadius: '10px' }
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('profile.menu.warningTip')), 1 /* TEXT */)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_el_button, {
            class: "flex-1 mr-3 text-gray-700",
            size: "large",
            onClick: _cache[0] || (_cache[0] = $event => ($setup.showDialog = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('organization.members.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_button, {
            class: "flex-1",
            size: "large",
            color: "#3250BD",
            type: "primary",
            loading: _ctx.loading,
            onClick: $setup.handleConfirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('organization.members.confirm')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('profile.menu.warningTipDesc')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}