import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cadbe6f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "items-center pl-3" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "text-[14px] font-[300] leading-[20px] text-[#475467]"
}
const _hoisted_5 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_6 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_7 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_8 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_9 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_10 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      class: "resource-table",
      "header-row-class-name": "resource-table-header-row",
      "header-cell-class-name": "resource-table-header-cell",
      "row-class-name": "resource-table-row",
      "cell-class-name": "resource-table-row-cell",
      data: $props.resource,
      stripe: "",
      style: {"width":"100%"},
      "empty-text": _ctx.$t('all.noData')
    }, {
      default: _withCtx(() => [
        _createCommentVNode(" resource type "),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('resourceConsole.resourceType'),
          "label-class-name": "indent-3 text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (!!scope.row.resource_type)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, " #" + _toDisplayString(scope.row.resource_type), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" resource id "),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('resourceConsole.resourceId'),
          "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_5, _toDisplayString(scope.row.resource_id), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" resource "),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('resourceConsole.resource'),
          "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_6, _toDisplayString(scope.row.resource), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" start time "),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('resourceConsole.startTime'),
          "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_7, _toDisplayString($setup.formatDate(scope.row.start_time)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" end time "),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('resourceConsole.endTime'),
          "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_8, _toDisplayString($setup.formatDate(scope.row.end_time)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" price "),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('resourceConsole.price'),
          "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_9, _toDisplayString((Math.abs(scope.row.price)/100.0).toFixed(2)) + " / " + _toDisplayString(scope.row.pay_mode), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" deploy_name "),
        _createVNode(_component_el_table_column, {
          width: "200",
          align: "center",
          label: _ctx.$t('resourceConsole.instance'),
          fixed: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("a", {
              href: $setup.detailLink(scope.row),
              class: "flex gap-4 justify-end pr-4 text-[#223B99]"
            }, _toDisplayString(scope.row.deploy_name), 9 /* TEXT, PROPS */, _hoisted_10)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "empty-text"])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}