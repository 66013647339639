export const navbar = {
  "allSolution":"全部解决方案",
  "models": "模型",
  "datasets": "数据",
  spaces: "应用",
  codes: "代码",
  "product": "产品",
  "openSourceStarCloud":"开源StarCloud",
  "openSourceStarCloudText":"开源算力调度平台",
  "collections": "内容合集",
  "collectionsText": "按主题聚合模型、数据集、代码和应用",
  "starCloud": "StarCloud 星云平台",
  "starCloudText": "一站式大模型开发及服务运行平台，包括算力资源调度及算法训练推理服务",
  "csgHub": "CSGHub 大模型资产管理平台",
  "csgHubText": "一个开源开放的、针对大模型原生设计的资产管理平台",
  "starShip": "StarShip 星舰平台",
  "starShipText": "为大模型应用开发团队提供一套完整的模型/数据管理及应用搭建平台",
  "starChain":"StarChain 星链平台",
  "starChainText":"LLM应用搭建、测试及分享平台",
  "solution": "方案",
  "EKB": "EKB AI 智能企业知识库",
  "EKBText": "大模型时代企业知识全生命周期管理基座，给企业客户的一站式解决方案",
  "starAIO": "StarAIO 大模型一体机",
  "starAIOText": "集成OpenCSG软件平台及精选开源大模型的软硬一体化解决方案",
  "imagen": "StarImagen  AI 智能图像助手",
  "imagenText": "为创作者提供更好用的工具，实现创意和生产力的指数级放大",
  "starCode": "StarCode AI 智能编程助手",
  "starCodeText": "基于大模型技术的创新功能，帮助开发者提高工作效率",
  "developer": "开源",
  "open": "开源CSGHub",
  "openText": "开源、可信的大模型资产管理平台",
  "llm_inference_open": "开源Inference",
  "llm_inference_open_text": "高效部署 LLM 推理服务",
  "llm_finetune":"开源Finetune",
  "llm_finetune_text":"LLM 训练及微调框架",
  "space": "应用空间",
  "spaceText": "大模型应用体验平台",
  "news": "新闻动态",
  "newsText": "分享最新最热的资讯和文章",
  "docs": "文档中心",
  "docsText": "传神社区产品说明及常见问题解答",
  "computer": "算力",
  "enterprise": "公司",
  "about": "公司介绍",
  "aboutText": "关于 OpenCSG 团队",
  "partner": "合作伙伴",
  "partnerText": "携手合作伙伴共建解决方案生态",
  "expert": "技术专家",
  "expertText": "与专家一起共建、共享开源开放生态",
  "community": "社区",
  "zone": "专区",
  "campaign": "社区活动",
  "campaignText": "汇聚行业和领域内顶尖会议及线下活动",
  "dailyPaper": "论文中心",
  "dailyPaperText": "汇聚最新研究成果，分享前沿学术论文",
  "eaiArticle": "具身智能",
  "eaiArticleText": "融合软硬件与论文的智能机器人专区",
  "profile": "个人信息",
  "setting": "账号设置",
  editProfile: "账号设置",
  "contact": "联系我们",
  "newModel": "新建模型",
  "newDataset": "新建数据集",
  newCode: "新建代码仓库",
  newApplicationSpace: "新建应用空间",
  "newOrg": "新建组织",
  newOrganization: "新建组织",
  "recommendation": "论文推荐",
  "logout": "退出登录",
  "register": "注册",
  loginRegister: "登录/注册",
  "login": "登录",
  "source": "资源控制台",
  newCollection: "新建合集",
  emailMissing: '体验完整功能，请提供您的电子邮件',
  "more": "更多"
}
