import request from './request'

// 代码仓库查询
export function codeSearch(query) {
  return request({
    url: `/api/v1/codes`,
    method: 'get',
    params: query
  })
}

// 代码仓库详情
export function codeDetail(path) {
  return request({
    url: `/api/v1/codes/${path}`,
    method: 'get',
  })
}

