import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border border-[#DCDFE6] rounded-[8px] my-[32px] md:my-0 md:border-none px-[24px] py-[24px]" }
const _hoisted_2 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_3 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_4 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_5 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_6 = { class: "flex flex-col gap-[6px]" }
const _hoisted_7 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_8 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_9 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_10 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_11 = { class: "flex flex-col gap-[6px]" }
const _hoisted_12 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_13 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_14 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_15 = { class: "text-[14px] text-[#475467] font-light leading-[20px]" }
const _hoisted_16 = { class: "flex flex-col gap-[6px]" }
const _hoisted_17 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_18 = { class: "w-[380px] sm:w-full flex flex-col" }
const _hoisted_19 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_20 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_21 = { class: "text-black font-semibold" }
const _hoisted_22 = { class: "flex flex-col gap-[6px]" }
const _hoisted_23 = { class: "text-[#344054] text-[14px]" }
const _hoisted_24 = { class: "flex xl:flex-col gap-[32px]" }
const _hoisted_25 = { class: "w-[380px] sm:w-full flex flex-col gap-[6px]" }
const _hoisted_26 = { class: "text-[14px] text-[#344054] leading-[20px] font-medium" }
const _hoisted_27 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_28 = { class: "text-black font-medium" }
const _hoisted_29 = { class: "text-black font-medium break-words" }
const _hoisted_30 = { class: "text-[14px] text-[#475467] leading-[20px]" }
const _hoisted_31 = { class: "text-black font-medium break-words" }
const _hoisted_32 = { class: "flex flex-col gap-[8px]" }
const _hoisted_33 = { class: "text-[#344054] text-[14px]" }
const _hoisted_34 = { class: "flex" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_divider = _resolveComponent("el-divider")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" collection name "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('collections.edit.cnName')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('collections.edit.cnNameTips')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_input, {
          modelValue: $setup.collectionNickname,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.collectionNickname) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = $event => ($setup.updateCollection())),
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" collection desc "),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('collections.edit.desc')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('collections.edit.descTips')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_el_input, {
          modelValue: $setup.collectionDesc,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.collectionDesc) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_el_button, {
          onClick: _cache[3] || (_cache[3] = $event => ($setup.updateCollection())),
          class: "w-[100px]"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('all.update')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" theme "),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('collections.edit.theme')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('collections.edit.upDateTheme')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_el_select, {
          modelValue: $setup.themeColor,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.themeColor) = $event)),
          placeholder: _ctx.$t('all.select'),
          onChange: _cache[5] || (_cache[5] = $event => ($setup.updateCollection())),
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.colorNameList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item[0],
                label: item[1],
                value: item[0]
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "placeholder"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" add project "),
    _createCommentVNode(" <div class=\"flex xl:flex-col gap-[32px]\">\n      <div class=\"w-[380px] sm:w-full flex flex-col\">\n        <div class=\"text-[14px] text-[#344054] leading-[20px] font-medium\">增加项目</div>\n        <div class=\"text-[14px] text-[#475467] font-light leading-[20px]\">增加更多项目到集合</div>\n      </div>\n      <div class=\"flex flex-col gap-[6px]\">\n        <el-select\n          v-model=\"themeColor\"\n          :placeholder=\"$t('all.select')\"\n          size=\"large\"\n          class=\"!w-[512px] sm:!w-full\"\n          disabled\n        >\n          <el-option\n            v-for=\"item in colorNameList\"\n            :key=\"item[0]\"\n            :label=\"item[1]\"\n            :value=\"item[0]\"\n          />\n        </el-select>\n      </div>\n    </div>\n    <el-divider /> "),
    _createCommentVNode(" Change Visibility "),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('collections.edit.changeVisibility')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_20, [
          _createTextVNode(_toDisplayString(_ctx.$t('collections.edit.statusText')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_21, "[" + _toDisplayString($setup.visibility ? _ctx.$t('all.private') : _ctx.$t('all.public')) + "]", 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('collections.edit.status')) + " " + _toDisplayString($setup.visibility ? _ctx.$t('collections.edit.privateVis') : _ctx.$t('collections.edit.publicVis')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t('collections.edit.collectionVisibility')), 1 /* TEXT */),
        _createVNode(_component_el_select, {
          modelValue: $setup.visibility,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($setup.visibility) = $event)),
          onChange: $setup.changeVisibility,
          placeholder: "Select",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createVNode(_component_el_divider),
    _createCommentVNode(" 删除应用空间 "),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('collections.edit.delCollection')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_27, [
          _createTextVNode(_toDisplayString(_ctx.$t('collections.edit.delTips')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t('collections.edit.canNot')), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('collections.edit.delTips2')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.path), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('collections.edit.delTips3')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createTextVNode(_toDisplayString(_ctx.$t('all.enterPls')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_31, _toDisplayString(`${$props.collection.username}/${$props.collection.name}`), 1 /* TEXT */),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('all.sureDel')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.$t('collections.collectionName')), 1 /* TEXT */),
        _createVNode(_component_el_input, {
          modelValue: $setup.delDesc,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($setup.delDesc) = $event)),
          clearable: "",
          size: "large",
          class: "!w-[512px] sm:!w-full"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", {
            id: "confirmDelete",
            onClick: $setup.clickDelete,
            class: _normalizeClass(["text-[#98A2B3] py-[8px] px-[12px] text-[14px] leading-[20px] rounded-[8px]", 
              $setup.delDesc === `${$props.collection.username}/${$props.collection.name}`
                ? 'bg-[#D92D20] text-[#FFFFFF] cursor-pointer active:shadow-box active:space-y-0 active:space-x-0 active:ring-4 active:ring-red-400 active:ring-opacity-25 active:bg-[#D92D20] hover:text-white'
                : 'bg-[#F2F4F7]'
            ]),
            onMouseover: $setup.handleMouseOver,
            onMouseleave: $setup.handleMouseLeave
          }, _toDisplayString(_ctx.$t('endpoints.settings.confirmDel')), 35 /* TEXT, CLASS, NEED_HYDRATION */)
        ])
      ])
    ])
  ]))
}