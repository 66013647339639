import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex items-center justify-between mb-1" }
const _hoisted_3 = { class: "flex gap-2" }
const _hoisted_4 = { class: "flex flex-nowrap overflow-hidden text-ellipsis items-center gap-[8px] text-xs text-[#909399]" }
const _hoisted_5 = { class: "overflow-hidden text-ellipsis whitespace-nowrap" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: $setup.detailLink,
    class: _normalizeClass([`${$props.repoType}-card  hover:active-${$props.repoType}-card `, "focus:outline focus:outline-4 focus:outline-[#EAECF0] hover:shadow-md p-4 mlg:w-full border border-gray-200 rounded-xl"])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([`${$props.repoType}-path`, "text-md text-[#344054] font-normal text-ellipsis overflow-hidden whitespace-nowrap"])
      }, _toDisplayString($props.repo.deploy_name), 3 /* TEXT, CLASS */),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["AppPayMode"], {
          payMode: $props.repo.pay_mode
        }, null, 8 /* PROPS */, ["payMode"]),
        _createVNode($setup["AppStatus"], {
          appStatus: $props.repo.status || 'NoAppFile',
          spaceResource: $props.repo.hardware
        }, null, 8 /* PROPS */, ["appStatus", "spaceResource"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString($props.repo.model_id) + " " + _toDisplayString($props.repo.updated_at.substring(0, 10)), 1 /* TEXT */)
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}