import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full sm:mt-[36px] py-9 text-[#101828]" }
const _hoisted_2 = { class: "text-[30px] leading-[38px]" }
const _hoisted_3 = { class: "text-[18px] mt-6 flex justify-between gap-[8px]" }
const _hoisted_4 = {
  href: "/computing",
  class: "cursor-pointer mx-[6px] flex gap-1 items-center py-2 px-3 text-[14px] leading-[20px] text-[#FFF] bg-[#3250BD] border border-[#3250BD] rounded-[8px]"
}
const _hoisted_5 = { class: "mt-6 mb-4" }
const _hoisted_6 = { class: "mt-3 flex justify-center" }
const _hoisted_7 = { class: "text-[18px] flex justify-between gap-[8px]" }
const _hoisted_8 = {
  href: "/finetune/new",
  class: "cursor-pointer mx-[6px] flex gap-1 items-center py-2 px-3 text-[14px] leading-[20px] text-[#FFF] bg-[#3250BD] border border-[#3250BD] rounded-[8px]"
}
const _hoisted_9 = { class: "mt-[18px]" }
const _hoisted_10 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-8 mt-[16px]"
}
const _hoisted_11 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-8 mt-[16px]"
}
const _hoisted_12 = { class: "text-[18px] flex justify-between gap-[8px]" }
const _hoisted_13 = {
  href: "/endpoints/new",
  class: "cursor-pointer mx-[6px] flex gap-1 items-center py-2 px-3 text-[14px] leading-[20px] text-[#FFF] bg-[#3250BD] border border-[#3250BD] rounded-[8px]"
}
const _hoisted_14 = { class: "mt-[18px] w-full" }
const _hoisted_15 = {
  key: 0,
  class: "grid grid-cols-2 lg:grid-cols-1 gap-4 mb-8 mt-[16px]"
}
const _hoisted_16 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-8 mt-[16px]"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_skeleton = _resolveComponent("el-skeleton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("resourceConsole.title")), 1 /* TEXT */),
    _createCommentVNode(" computing_power "),
    _createElementVNode("h3", _hoisted_3, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('resourceConsole.yearMonthResources')), 1 /* TEXT */),
      _createElementVNode("a", _hoisted_4, [
        _createVNode(_component_SvgIcon, { name: "plus" }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('resourceConsole.buy')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["ResourceTable"], { resource: $setup.computingPowers }, null, 8 /* PROPS */, ["resource"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode($setup["CsgPagination"], {
          perPage: $setup.defaultTotal,
          currentPage: $setup.computingPowersCurrentPage,
          onCurrentChange: $setup.fetchComputingData,
          total: $setup.totalComputing
        }, null, 8 /* PROPS */, ["currentPage", "total"])
      ])
    ]),
    _createCommentVNode(" finetunes "),
    _createElementVNode("h3", _hoisted_7, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("finetune.title")), 1 /* TEXT */),
      _createElementVNode("a", _hoisted_8, [
        _createVNode(_component_SvgIcon, { name: "plus" }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('resourceConsole.new')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      ($setup.hasFinetune)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.finetunes.data, (finetune) => {
              return (_openBlock(), _createBlock($setup["FinetuneItem"], {
                repo: finetune,
                "repo-type": "finetune"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.finetunes.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "finetunes",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.finetunesLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" endpoints "),
    _createElementVNode("h3", _hoisted_12, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("endpoints.title")), 1 /* TEXT */),
      _createElementVNode("a", _hoisted_13, [
        _createVNode(_component_SvgIcon, { name: "plus" }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('resourceConsole.new')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      ($setup.hasEndpoints)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.endpoints.data, (endpoint) => {
              return (_openBlock(), _createBlock($setup["EndpointItem"], {
                endpoint: endpoint,
                namespace: _ctx.name
              }, null, 8 /* PROPS */, ["endpoint", "namespace"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.endpoints.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "endpoints",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.endpointsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}