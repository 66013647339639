<template>
  <el-form
    ref="dataFormRef"
    :model="dataForm"
    :rules="rules"
    class="w-full flex flex-col gap-[14px]"
    label-position="top"
  >
    <el-form-item
      label="Source Url"
      prop="source_url"
      class="w-full"
    >
      <el-input v-model="dataForm.source_url" />
    </el-form-item>
    <el-form-item
      label="Mirror Source Id"
      prop="mirror_source_id"
      class="w-full"
    >
      <el-select v-model="dataForm.mirror_source_id" placeholder="请选择">
        <el-option v-for="source in selectSourcesOptions" :label="source.source_name" :value="source.id" />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Username"
      prop="username"
      class="w-full"
    >
      <el-input v-model="dataForm.password" />
    </el-form-item>
    <el-form-item
      label="Accese Token"
      prop="password"
      class="w-full"
    >
      <el-input v-model="dataForm.acceseToken" />
    </el-form-item>
    <el-form-item
      label="Mirrorable Type"
      porp="mirrorableType"

      class="w-full"
    >
      <el-select v-model="dataForm.mirrorableType" placeholder="请选择">
        <el-option label="Model" value="models" />
        <el-option label="Dataset" value="datasets" />
        <el-option label="Code" value="codes" />
        <el-option label="Space" value="spaces" />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Repo Path"
      prop="repoPath"
      class="w-full"
    >
      <el-input v-model="dataForm.repoPath" />
    </el-form-item>

    <el-form-item class="w-full">
      <div class="flex justify-end w-full">
        <el-button
          type="primary"
          @click="handleSubmit"
        >
          提交
        </el-button>
      </div>
    </el-form-item>
  </el-form>

</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { ElMessage } from 'element-plus'
  import { useRouter } from 'vue-router'
  import useFetchApi from "../../../packs/useFetchApi"

  const router = useRouter()

  const dataForm = ref({ mirrorableType: 'models' });
  const dataFormRef = ref(null);
  const rules = {
    source_url: [{ required: true, message: "Source Url 必填", trigger: "blur" }],
    mirror_source_id: [{ required: true, message: "Mirror Source 描述必填", trigger: "blur" }],
    repoPath: [{ required: true, message: "Repo Path 必填", trigger: "blur" }]
  };
  const selectSourcesOptions = ref({ label: '', value: '' })

  const handleSubmit = () => {
    dataFormRef.value.validate((valid) => {
      if (valid) {
        createMirror();
      } else {
        return false;
      }
    });
  };
  
  const createMirror = async() => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataForm.value)
    }

    const { data, error } = await useFetchApi(
      `/${dataForm.value.mirrorableType}/${dataForm.value.repoPath}/mirror`, 
      options
    ).json()

    if (error.value) {
      ElMessage.error('Failed to create mirror source!')
    } else {
      ElMessage.success('添加成功')
      router.push({path: `/new_admin/mirrors`});
    }
  };

  const fetchMirrorSources = async () => {
    const { data, error } = await useFetchApi(`/mirror/sources`).json()

    if (error.value) {
      ElMessage.error('Failed to fetch mirror sources')
    } else {
      const body = data.value
      selectSourcesOptions.value = body.data
    }
  }

  onMounted(() => {
    fetchMirrorSources()
  });
</script>


