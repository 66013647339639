export const eaiArticle = {
  eai: "具身智能",
  dailyPapers: "相关文章",
  relatedDevices: "相关设备",
  relatedCodes: "代码仓库",
  learnMore: "了解更多",
  updatedAt: "更新时间",
  kind: {
    open_source: "开源项目",
    famous_company: "知名企业",
  },
  list: {
    bannerTitle: "融合本体与智能的前沿探索",
    bannerDesc: "具身智能是人工智能分支，融合智能与本体，涉及多要素，虽有挑战但应用广，跨多学科。",
  }
}