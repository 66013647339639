export const resourceConsole = {
  free: "免费",
  minute: "按需付费",
  yearMonth: "包年包月",
  others:"其他",
  title: "资源管理",
  yearMonthResources:'包年包月资源',
  buy:'购买资源',
  new:'新建实例',
  resourceType:'资源类型',
  resourceId:'资源ID',
  resource:'配置',
  startTime:'启动时间',
  endTime:'到期时间',
  price:'价格',
  instance:'实例'
}