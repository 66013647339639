export const eaiArticle = {
  eai: "Embodied Intelligence",
  dailyPapers: "Related Articles",
  relatedDevices: "Related Devices",
  relatedCodes: "Code Repository",
  learnMore: "Learn More",
  updatedAt: "Updated At",
  kind: {
    open_source: "Open Source Project",
    famous_company: "Famous Company",
  },
  list: {
    bannerTitle: "Integrating Ontology and Intelligence",
    bannerDesc: "Embodied intelligence combines AI with ontology, offering broad, multidisciplinary applications despite its challenges.",
  }
}