import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 lg:grid-cols-1 gap-9" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "text-[14px] leading-[20px] text-[#344054] text-ellipsis overflow-hidden whitespace-nowrap" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = {
  key: 0,
  class: "p-4 mlg:w-full border border-gray-200 rounded-xl flex justify-center items-center"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.collections, (collection) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "rounded-[12px]",
        key: collection.id,
        style: _normalizeStyle($setup.generateGradientStyle(collection.theme))
      }, [
        _createElementVNode("div", {
          class: "active:outline active:outline-4 active:outline-[#EAECF0] hover:shadow-md px-4 pt-4 pb-2 w-full rounded-[12px] border border-[#EAECF0] cursor-pointer",
          onClick: $event => ($setup.goCollectionDetails(collection.id))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(collection.nickname || collection.name), 1 /* TEXT */),
            _createVNode(_component_SvgIcon, { name: "chevron_right" })
          ]),
          _createElementVNode("div", {
            title: collection.description,
            class: "overflow-hidden text-ellipsis line-clamp-2 text-[14px] leading-[20px] font-light text-[#475467] mb-2"
          }, _toDisplayString(collection.description), 9 /* TEXT, PROPS */, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(collection.repositories, (repo) => {
              return (_openBlock(), _createBlock($setup["CollectionCard"], { reposData: repo }, null, 8 /* PROPS */, ["reposData"]))
            }), 256 /* UNKEYED_FRAGMENT */)),
            (!collection.repositories)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('all.noData')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ])
        ], 8 /* PROPS */, _hoisted_2)
      ], 4 /* STYLE */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}