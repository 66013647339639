<template>
  <div class="bg-[#E0E2E4] pt-[17px] w-full">
    <div class="max-w-[1094px] w-full m-auto flex justify-between items-center md:px-[27px] md:h-[160px] md:relative md:overflow-hidden">
      <div class="flex flex-col max-w-[432px] w-full gap-[24px] z-[10]">
        <div class="flex flex-col gap-[8px] md:w-[192px]">
          <h1 class="text-[36px] md:text-[16px] md:leading-[28px] font-[600] leading-[40px] text-[var(--Gray-800)]">{{ t('eaiArticle.eai') }}:</h1>
          <h2 class="text-[36px] md:text-[16px] md:leading-[28px] font-[600] leading-[40px] text-[var(--Gray-800)]">{{ t('eaiArticle.list.bannerTitle') }}</h2>
        </div>
        <p class="text-[16px] font-[400] leading-[24px] text-[var(--Gray-600)] md:hidden">{{ t('eaiArticle.list.bannerDesc') }}</p>
      </div>
      <img src="/images/eai_article/banner.png" class="h-[317px] md:h-[160px] md:absolute right-[-20px] z-[5] object-cover" />
    </div>
  </div>
  <div class="bg-white max-w-[1280px] w-full m-auto pt-[32px] md:px-[20px] mb-[32px]">
    <div class="flex items-center gap-[68px] mb-[60px] border-b">
      <div class="text-[var(--Gray-800)] text-[18px] font-[500] leading-[28px] cursor-pointer" @click="handleRemoveKind">{{ t('eaiArticle.eai') }}</div>
      <el-tabs v-model="activeKindTab" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane :label="t('eaiArticle.kind.open_source')" name="open_source"></el-tab-pane>
        <el-tab-pane :label="t('eaiArticle.kind.famous_company')" name="famous_company"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="grid grid-cols-3 justify-items-center lg:grid-cols-2 md:grid-cols-1 gap-x-[32px] gap-y-[40px]">
      <template v-for="article in eaiArticles" :key="article.id">
        <EaiArticleDetailCard :article="article" />
      </template>
    </div>
  </div>
  <CsgPagination
    :perPage="per"
    :currentPage="currentPage"
    @currentChange="fetchEaiArticles"
    :total="total"
  />
</template>
<script setup>
  import { ref, onMounted } from "vue";
  import { useI18n } from 'vue-i18n'
  import CsgPagination from '../shared/CsgPagination.vue'
  import { eaiArticleList } from "../../api/csrf/eai"
  import EaiArticleDetailCard from "./EaiArticleDetailCard.vue";
  import { ElMessage } from 'element-plus'

  const { t } = useI18n()
  const eaiArticles = ref([]);
  onMounted(() => {
    fetchEaiArticles();
  });

  const keyword = ref("");
  const per = ref(12);
  const currentPage = ref(1);
  const total = ref(0);
  const activeKindTab = ref("");

  const fetchEaiArticles = (page) => {
    eaiArticleList({
      keyword: keyword.value,
      kind: activeKindTab.value,
      page: page || currentPage.value,
      per: per.value,
    }).then((res) => {
      eaiArticles.value = res.data;
      total.value = res.total;
      currentPage.value = page || res.page;
    }).catch((err) => {
      ElMessage.error(err.message);
    });
  };

  const handleClick = (tab) => {
    activeKindTab.value = tab.props.name;
    fetchEaiArticles();
  };

  const handleRemoveKind = () => {
    activeKindTab.value = "";
    fetchEaiArticles();
  };
</script>
<style scoped>
  :deep(.el-tabs__nav-wrap:after) {
    background-color: transparent;
  }
</style>