import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sm:w-[100%] sm:mt-[36px]" }
const _hoisted_2 = { class: "text-[20px] text-[#303133] flex items-center gap-[8px]" }
const _hoisted_3 = {
  key: 0,
  class: "mb-4 mt-[16px]"
}
const _hoisted_4 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_5 = { class: "text-[20px] text-[#344054] flex items-center gap-[8px]" }
const _hoisted_6 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_7 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_8 = { class: "mt-[32px]" }
const _hoisted_9 = { class: "text-[20px] text-[#344054] flex items-center gap-[8px]" }
const _hoisted_10 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_11 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_12 = { class: "mt-[32px]" }
const _hoisted_13 = { class: "text-[20px] text-[#344054] flex items-center gap-[8px]" }
const _hoisted_14 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_15 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}
const _hoisted_16 = { class: "mt-[32px]" }
const _hoisted_17 = { class: "text-[20px] text-[#344054] flex items-center gap-[8px]" }
const _hoisted_18 = {
  key: 0,
  class: "grid grid-cols-2 xl:grid-cols-1 gap-4 mb-4 mt-[16px]"
}
const _hoisted_19 = {
  key: 1,
  class: "flex flex-wrap gap-4 mb-4 mt-[16px]"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_el_skeleton = _resolveComponent("el-skeleton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" collections "),
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_2, [
        _createVNode(_component_SvgIcon, {
          name: "collections",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("collections.collection")), 1 /* TEXT */)
      ]),
      ($setup.hasCollections)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            ($setup.collections.data.length > 0)
              ? (_openBlock(), _createBlock($setup["CollectionCards"], {
                  key: 0,
                  collections: $setup.collections.data
                }, null, 8 /* PROPS */, ["collections"]))
              : _createCommentVNode("v-if", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.collections.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "collections",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.collectionsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" models "),
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_5, [
        _createVNode(_component_SvgIcon, {
          name: "models",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.model")), 1 /* TEXT */)
      ]),
      ($setup.hasModels)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.models.data, (model) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: model,
                "repo-type": "model"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.models.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "models",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.modelsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" datasets "),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("h3", _hoisted_9, [
        _createVNode(_component_SvgIcon, {
          name: "datasets",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.dataset")), 1 /* TEXT */)
      ]),
      ($setup.hasDatasets)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.datasets.data, (dataset) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: dataset,
                "repo-type": "dataset"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.datasets.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "datasets",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.datasetsLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" code repo "),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("h3", _hoisted_13, [
        _createVNode(_component_SvgIcon, {
          name: "codes",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.code")), 1 /* TEXT */)
      ]),
      ($setup.hasCodes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.codes.data, (code) => {
              return (_openBlock(), _createBlock($setup["RepoItem"], {
                repo: code,
                "repo-type": "code"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.codes.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "codes",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.codeLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(" spaces "),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("h3", _hoisted_17, [
        _createVNode(_component_SvgIcon, {
          name: "spaces",
          width: "18",
          height: "18"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("organization.space")), 1 /* TEXT */)
      ]),
      ($setup.hasSpaces)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.spaces.data, (repo) => {
              return (_openBlock(), _createBlock($setup["ApplicationSpaceItem"], {
                repo: repo,
                "repo-type": "space"
              }, null, 8 /* PROPS */, ["repo"]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$t("all.noData")), 1 /* TEXT */)),
      ($setup.spaces.more)
        ? (_openBlock(), _createBlock($setup["ViewMore"], {
            key: 2,
            target: "spaces",
            onViewMoreTargets: $setup.viewMoreTargets
          }))
        : _createCommentVNode("v-if", true),
      ($setup.spacesLoading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 3,
            class: "pr-6",
            rows: 2,
            animated: ""
          }))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}