<template>
  <div class="max-w-[405px] w-full flex flex-col md:max-w-full rounded-[16px] border border-[var(--Gray-200)]">
    <a :href="`/daily_papers/${dailyPaper.uuid}`">
      <img :src="dailyPaper.cover_url" class="max-w-[405px] w-full h-[270px] rounded-[16px_16px_0_0] object-cover">
    </a>
    <div class="flex flex-col gap-[8px] bg-[var(--Gray-25)] rounded-[0_0_16px_16px] p-[20px]">
      <a :href="`/daily_papers/${dailyPaper.uuid}`">
        <div class="flex gap-[16px] justify-between items-center">
          <div class="text-[var(--Gray-900)] text-[24px] font-[500] leading-[32px] md:text-[20px] md:leading-[30px] line-clamp line-clamp-1">{{ dailyPaper.title }}</div>
          <div class="w-[24px] flex">
            <el-icon :size="24"><TopRight /></el-icon>
          </div>
        </div>
      </a>
      <div class="line-clamp line-clamp-2 text-[var(--Gray-500)] text-[16px] font-[400] leading-[24px] md:text-[14px] md:leading-[20px]">
        {{ dailyPaper.description }}
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref } from "vue";

  const props = defineProps({
    dailyPaper: {
      type: Object,
      default: () => ({}),
    }
  });

  const dailyPaper = ref(props.dailyPaper);
</script>