export const resourceConsole = {
  free: "Free",
  minute: "Pay-as-you-go",
  yearMonth: "Yearly Subscription",
  others:"Others",
  title: "Resource Management",
  yearMonthResources:'Yearly Subscription Resources',
  buy:'Buy',
  new:'Create',
  resourceType:'Resource Type',
  resourceId:'Resource ID',
  resource:'Configuration',
  startTime:'Start Time',
  endTime:'End Time',
  price:'Price',
  instance:'Instance'
}