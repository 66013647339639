import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd29c9bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rounded-[16px] bg-white p-[36px] w-[655px] md:w-full border border-[1px] border-[#EAECF0] shadow-sm" }
const _hoisted_2 = { class: "text-[#303133] text-[20px] font-[600]" }
const _hoisted_3 = { class: "mt-[6px]" }
const _hoisted_4 = { class: "text-[#475467] text-[14px] font-[300] leading-[20px]" }
const _hoisted_5 = { class: "el-upload__text" }
const _hoisted_6 = { class: "text-[#223B99] font-[500]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_upload_filled = _resolveComponent("upload-filled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('organization.newOrganization.title')), 1 /* TEXT */),
    _createVNode(_component_el_form, {
      ref: "dataFormRef",
      model: $data.dataForm,
      rules: $options.rules,
      "label-position": "top",
      class: "mt-[48px] text-left",
      style: {"--el-border-radius-base":"8px"}
    }, {
      default: _withCtx(() => [
        _createCommentVNode(" name "),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgNameSpace'),
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.dataForm.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.dataForm.name) = $event)),
              placeholder: _ctx.$t('rule.nameRule')
            }, {
              prepend: _withCtx(() => [
                _createTextVNode(_toDisplayString($options.getDomain()), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "placeholder"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('organization.orgSpaceTips')), 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" nickname "),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgNickName'),
          prop: "nickname"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.dataForm.nickname,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.dataForm.nickname) = $event)),
              placeholder: _ctx.$t('all.pleaseInput', {value: _ctx.$t('organization.orgNickName')})
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" org_type "),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgType'),
          prop: "org_type"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $data.dataForm.org_type,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.dataForm.org_type) = $event)),
              placeholder: _ctx.$t('all.pleaseSelect', {value: _ctx.$t('organization.orgType')}),
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.org_types, (value) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: value,
                    label: value,
                    value: value
                  }, null, 8 /* PROPS */, ["label", "value"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "placeholder"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" homepage "),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgHomepage'),
          prop: "homepage"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.dataForm.homepage,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.dataForm.homepage) = $event)),
              placeholder: "example.com",
              onBlur: $options.formatHomepage
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.selectedProtocol,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.selectedProtocol) = $event)),
                  style: {"width":"100px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "https://",
                      value: "https://"
                    }),
                    _createVNode(_component_el_option, {
                      label: "http://",
                      value: "http://"
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "onBlur"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createCommentVNode(" avatar "),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('organization.orgAvatar'),
          prop: "logo_image"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_upload, {
              class: _normalizeClass(`w-full ${$data.showUpload ? 'h-[229px]' : 'hide'}`),
              limit: 1,
              "file-list": $data.dataForm.logo_image,
              "onUpdate:fileList": _cache[5] || (_cache[5] = $event => (($data.dataForm.logo_image) = $event)),
              drag: "",
              "list-type": "picture",
              headers: { 'X-CSRF-TOKEN': $data.csrf_token },
              accept: "image/png, image/jpeg, image/gif, image/svg+xml",
              data: {
            namespace: 'org-logo',
            file_max_size: 1024*1024
          },
              action: "/internal_api/upload",
              "on-progress": $options.handleUploadProgress,
              "on-remove": $options.handleRemoveImage,
              "on-success": $options.handleUploadSuccess,
              "on-error": $options.handleUploadError
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('components.upload.click_to_upload')), 1 /* TEXT */),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('components.upload.or_drag_file')), 1 /* TEXT */)
                    ]),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('components.upload.upload_type', {accept: 'SVG, PNG, JPG, GIF'})), 1 /* TEXT */)
                  ]),
                  _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_upload_filled)
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class", "file-list", "headers", "on-progress", "on-remove", "on-success", "on-error"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "w-full !text-center !h-[48px] !text-[16px] btn btn-primary",
              onClick: $options.handleSubmit,
              disabled: $data.submitting
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('organization.newOrganization.createOrg')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick", "disabled"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model", "rules"])
  ]))
}