<template>
  <el-card>
    <template #header>
      <div class="flex items-center justify-between mb-[20px]">
        <h3 class="text-[20x] font-[500]">具身硬件设备</h3>
        <div class="flex justify-end gap-2">
          <el-input
            v-model="keyword"
            style="width: 240px"
            placeholder="标题，描述"
            :prefix-icon="Search"
            @input="fetchEaiDevices"
          />
          <router-link to="/new_admin/eai_devices/new">
            <el-button type="primary">创建</el-button>
          </router-link>
        </div>
      </div>
    </template>

    <el-table
      :stripe="true"
      :data="eaiDevices"
      style="width: 100%"
    >
      <el-table-column
        prop="title"
        label="标题"
      />
      <el-table-column
        prop="cover_url"
        label="封面"
      >
        <template #default="{ row }">
          <img
            :src="row.cover_url"
            alt="cover"
            class="h-[100px]"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        label="描述"
      >
        <template #default="{ row }">
          <span class="line-clamp line-clamp-2">{{ row.description }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="link"
        label="链接"
      >
        <template #default="{ row }">
          <a class="line-clamp line-clamp-2" :href="row.link" target="_blank">{{ row.link }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="创建时间"
      >
        <template #default="{ row }">
          {{ new Date(row.created_at).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <div class="flex gap-2">
            <router-link :to="`/new_admin/eai_devices/${eaiDevices[scope.$index].id}`">
              <el-button size="small">
                详情
              </el-button>
            </router-link>
            <router-link :to="`/new_admin/eai_devices/${eaiDevices[scope.$index].id}/edit`">
              <el-button size="small">编辑</el-button>
            </router-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="page"
      :page-size="per"
      layout="prev, pager, next"
      :total="total"
      @current-change="fetchEaiDevices"
      class="my-[52px] flex justify-center"
    />
  </el-card>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { adminEaiDeviceList } from "../../../api/csrf/admin_eai"
  import { ElMessage } from 'element-plus'

  const eaiDevices = ref([]);
  const keyword = ref("");
  const page = ref(1);
  const per = ref(10);
  const total = ref(0);

  const fetchEaiDevices = () => {
    adminEaiDeviceList({
      keyword: keyword.value,
      page: page.value,
      per: per.value,
    }).then((res) => {
      eaiDevices.value = res.data;
      total.value = res.total;
    }).catch((err) => {
      ElMessage.error(err.message);
    });
  };

  onMounted(() => {
    fetchEaiDevices();
  });
</script>