<template>
	<div class="max-w-[963px] w-full pt-[68px] pr-[120px] md:pt-[40px] md:px-0 border-r border-[#EAECF0] md:border-0">
		<div class="ck-content js-toc-content" v-html="data"></div>
	</div>
	<div class="pt-[68px] w-full flex-1 md:hidden">
		<div class="sticky top-[40%]">
			<div class="toc js-toc"></div>
		</div>
	</div>
	<el-dialog
		v-model="dialogImagePreview"
		width="90%"
		style="--el-dialog-bg-color: transparent; --el-dialog-box-shadow: unset">
    <img :src="dialogImageUrl" alt="" style="width: 100%">
  </el-dialog>
</template>

<script setup>
	import { defineProps, watch, ref, nextTick } from 'vue';
	import tocbot from 'tocbot'
  import hljs from 'highlight.js'

	const props = defineProps({
		content: {
			type: String,
			default: '',
		},
	});

	const data = ref(props.content);
	const dialogImagePreview = ref(false);
	const dialogImageUrl = ref('');

	watch(() => props.content, (newVal) => {
		data.value = newVal;
		nextTick(() => {
			generateTableOfContent();
			imagePreview();
			highlightLanguage();
		});
	});

	const generateToken = () => {
		return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
	};

	const generateTableOfContent = () => {
		document.querySelectorAll('.js-toc-content h2, .js-toc-content h3, .js-toc-content h4, .js-toc-content h5, .js-toc-content h6').forEach((heading) => {
			heading.id = generateToken();
		});

		const options = {
			tocSelector: '.js-toc',
			contentSelector: '.js-toc-content',
			headingSelector: 'h2, h3, h4, h5',
		}

		tocbot.init(options)
	};

	const imagePreview = () => {
		const images = document.querySelectorAll('.js-toc-content img');
		images.forEach((image) => {
			image.addEventListener('click', () => {
				dialogImageUrl.value = image.src;
				dialogImagePreview.value = true;
			});
		});
	};

	const highlightLanguage = () => {
		const codes = document.querySelectorAll('.js-toc-content pre code');
		codes.forEach((code) => {
			hljs.highlightElement(code);
		});
	};

</script>

