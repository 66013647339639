export const computing = {
  "banner": {
    "service": "算力服务",
    "headline": "适合您的算力租赁计划",
    "desc": "我们认为 OpenCSG 的算力服务对所有的团队都是友好的，无论其规模大小。",
    "option1": "独占算力",
    "option2": "共享算力",
    "option3": "即将发布"
  },
  "page": {
    "partner1": "近百家可信智算合作伙伴",
    "partner2": "*仅展示部分合作伙伴",
    "partner3": "战略可信智算合作伙伴",
    "partner4": "金牌可信智算合作伙伴",
    "partner5": "银牌可信智算合作伙伴",
  },
  "price": {
    "gpu": "GPU 云主机",
    "consult": "咨询销售",
    "brand": "品牌",
    "specifications": "规格",
    "power": "算力",
    "config": "配置",
    "inventory": "数量",
    "service": "状态",
    "type": "类型",
    "price": "价格",
    "available": "可供",
    "unavailable": "暂无",
    "partnerAvailable": "合作伙伴提供",
    "physical": "物理机",
    "cloud": "裸金属/云主机",
    "4ka": "4卡",
    "8ka": "8卡",
    "NVIDIA": "英伟达/\nNVIDIA",
    "Huawei": "华为昇腾/\nHuawei",
    "Enflame": "燧原云燧/\nEnflame",
    "unit": "台",
    "month": "月",
    "console": "咨询销售",
    "waiting": "待定",
  },
  "problem": {
    "headline": "常见问题",
    "desc1": "您需要了解的有关产品和计费的一切信息",
    "desc2": "找不到您想要的答案？请联系我们的",
    "pre-sales": "售前工程团队",
    "part1": "如何租用算力？",
    "part1-": "请点击“咨询销售”填写您的信息，我们将第一时间为您提供专属服务。",
    "part2": "租赁费用是如何计算的？",
    "part2-": "目前我们提供按月度、季度和年度的租用计费方式。",
    "part3": "对服务不满意是否可以退款？",
    "part3-": "我们的退款政策将在服务条款和用户协议中明确说明，在签约前会确保您知悉相关服务的退款政策。 ",
    "part4": "是否能够提供其他规格的服务器？",
    "part4-": "我们正在积极扩充算力资源，也非常欢迎您联系我们告知您的需求，我们将努力为您寻找匹配的资源。",
    "part5": "如何保障我的数据安全？",
    "part5-": "我们将采取多种安全措施保障用户数据的保密性和完整性，请联系我们获取数据安全和隐私保护的具体信息。",
    "part6": "是否提供技术支持？",
    "part6-": "我们通过邮件和电话方式为客户提供7 x 24小时的技术支持服务。",
    "problem": "仍有疑问？",
    "problem-": "找不到您想要的答案？请联系我们的售前工程团队。",
    "contact": "联系我们",
  },
}
