import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["text-[14px] text-[#344054] px-[8px] py-[3px] rounded-[6px] cursor-pointer flex items-center gap-[3px] border border-[#D0D5DD]", { 'bg-[#4D6AD6]': $props.active, 'text-white': $props.active, 'border-[#4D6AD6]': $props.active }]),
    onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('handleTagClick', $props.tag)))
  }, [
    _createElementVNode("img", {
      src: '/images/tags/' + $props.tag.name + '.svg',
      class: "w-[14px] h-[14px]",
      style: _normalizeStyle($props.active ? 'filter: drop-shadow(1000px 0 0 white); transform: translate(-1000px);' : ''),
      alt: "",
      onerror: "this.style.display='none'"
    }, null, 12 /* STYLE, PROPS */, _hoisted_1),
    _createTextVNode(" " + _toDisplayString($setup.tagName), 1 /* TEXT */)
  ], 2 /* CLASS */))
}