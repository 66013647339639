import request from './request'

/** 具身智能设备 开始 */
// 搜索具身智能设备
export function eaiDeviceList(query) {
  return request({
    url: `/internal_api/eai_devices`,
    method: 'get',
    params: query
  })
}

/** 具身智能设备 结束 */

/** 具身智能文章 开始 */
// 搜索具身智能文章
export function eaiArticleList(query) {
  return request({
    url: `/internal_api/eai_articles`,
    method: 'get',
    params: query
  })
}

// 查看具身智能文章
export function eaiArticleDetail(uuid) {
  return request({
    url: `/internal_api/eai_articles/${uuid}`,
    method: 'get',
  })
}

/** 具身智能文章 结束 */