import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[841px] w-full flex h-[200px] md:flex-col md:h-[unset] md:max-w-[unset] md:bg-[var(--Gray-25)] md:border md:border-[#EAECF0] md:rounded-[8px]" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "w-full flex flex-col justify-between p-[32px]" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex flex-col gap-[8px] justify-between" }
const _hoisted_7 = { class: "text-[var(--Gray-900)] text-[18px] font-[500] leading-[28px] line-clamp line-clamp-1" }
const _hoisted_8 = { class: "text-[var(--Gray-600)] text-[16px] font-[300] leading-[24px] line-clamp line-clamp-2" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "text-[var(--Gray-500)] text-[14px] font-[400] leading-[20px] mr-[6px]" }
const _hoisted_11 = { class: "flex items-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowRight = _resolveComponent("ArrowRight")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: $setup.device.link,
      target: "_blank"
    }, [
      _createElementVNode("img", {
        src: $setup.device.cover_url,
        class: "w-[405px] md:w-full h-[200px] rounded-[16px_0px_0px_16px] md:rounded-0 object-cover"
      }, null, 8 /* PROPS */, _hoisted_3)
    ], 8 /* PROPS */, _hoisted_2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: $setup.device.link,
        target: "_blank"
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.device.title), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_8, _toDisplayString($setup.device.description), 1 /* TEXT */)
        ])
      ], 8 /* PROPS */, _hoisted_5),
      _createElementVNode("a", {
        href: $setup.device.link,
        target: "_blank",
        class: "flex items-center md:hidden"
      }, [
        _createElementVNode("div", _hoisted_10, _toDisplayString($setup.t('eaiArticle.learnMore')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_ArrowRight, { size: 14 })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ], 8 /* PROPS */, _hoisted_9)
    ])
  ]))
}