<template>
  <div class="w-full">
    <el-table
      class="resource-table"
      header-row-class-name="resource-table-header-row"
      header-cell-class-name="resource-table-header-cell"
      row-class-name="resource-table-row"
      cell-class-name="resource-table-row-cell"
      :data="resource"
      stripe
      v-loading="loading"
      style="width: 100%"
      :empty-text="$t('all.noData')"
    >
      <!-- resource type -->
      <el-table-column
        :label="$t('resourceConsole.resourceType')"
        label-class-name="indent-3 text-[12px] font-[400] leading-[18px] text-[#475467]"
        align="center"
      >
        <template #default="scope">
          <div class="items-center pl-3">
            <div class="flex flex-col">
              <div
                class="text-[14px] font-[300] leading-[20px] text-[#475467]"
                v-if="!!scope.row.resource_type"
              >
                #{{ scope.row.resource_type }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- resource id -->
      <el-table-column
        :label="$t('resourceConsole.resourceId')"
        label-class-name="text-[12px] font-[400] leading-[18px] text-[#475467]"
        align="center"
      >
        <template #default="scope">
          <div class="text-[14px] font-[400] leading-[20px] text-[#475467]">
            {{ scope.row.resource_id }}
          </div>
        </template>
      </el-table-column>

        <!-- resource -->
        <el-table-column
        :label="$t('resourceConsole.resource')"
        label-class-name="text-[12px] font-[400] leading-[18px] text-[#475467]"
        align="center"
      >
        <template #default="scope">
          <div class="text-[14px] font-[400] leading-[20px] text-[#475467]">
            {{ scope.row.resource }}
          </div>
        </template>
      </el-table-column>

      <!-- start time -->
      <el-table-column
        :label="$t('resourceConsole.startTime')"
        label-class-name="text-[12px] font-[400] leading-[18px] text-[#475467]"
        align="center"
      >
        <template #default="scope">
          <div class="text-[14px] font-[400] leading-[20px] text-[#475467]">
            {{ formatDate(scope.row.start_time) }}
          </div>
        </template>
      </el-table-column>

      <!-- end time -->
      <el-table-column
        :label="$t('resourceConsole.endTime')"
        label-class-name="text-[12px] font-[400] leading-[18px] text-[#475467]"
        align="center"
      >
        <template #default="scope">
          <div class="text-[14px] font-[400] leading-[20px] text-[#475467]">
            {{ formatDate(scope.row.end_time) }}
          </div>
        </template>
      </el-table-column>

      <!-- price -->
      <el-table-column
        :label="$t('resourceConsole.price')"
        label-class-name="text-[12px] font-[400] leading-[18px] text-[#475467]"
        align="center"
      >
        <template #default="scope">
          <div class="text-[14px] font-[400] leading-[20px] text-[#475467]">
            {{ (Math.abs(scope.row.price)/100.0).toFixed(2) }} / {{ scope.row.pay_mode }}
          </div>
        </template>
      </el-table-column>

      <!-- deploy_name -->
      <el-table-column
        width="200"
        align="center"
        :label="$t('resourceConsole.instance')"
        fixed="right"
      >
        <template #default="scope">
          <a
            :href="detailLink(scope.row)"
            class="flex gap-4 justify-end pr-4 text-[#223B99]"
            >{{ scope.row.deploy_name }}</a
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
  import { formatDate } from '../../packs/datetimeUtils'

  const props = defineProps({
    resource: Array
  })

  const detailLink = (resource) => {
    switch (resource.deploy_type) {
      case 1:
        return `/endpoints/${resource.repo_path ? resource.repo_path.split('/')[0] : ''}/${resource.deploy_name}/${resource.deploy_id}`
      case 2:
        return `/finetune/${resource.repo_path}/${resource.deploy_name}/${resource.deploy_id}`
      default:
        return ''
    }
  }
</script>

<style scoped lang="less">
  :deep(.resource-table) {
    .el-table__empty-block {
      border-left: 1px solid #eaecf0;
      border-right: 1px solid #eaecf0;
    }
    .resource-table-header-row {
      .resource-table-header-cell {
        padding: 12px 24px;
        font-size: 12px;
        font-weight: 400;
        color: var(--gray-600);
        &:nth-child(1) {
          border-left: 1px solid #eaecf0;
          border-top-left-radius: 8px;
        }
        &:nth-last-child(1) {
          border-right: 1px solid #eaecf0;
          border-top-right-radius: 8px;
        }
        .cell {
          line-height: 18px;
        }
        border-top: 1px solid #eaecf0;
        background-color: #f9fafb;
      }
    }
    .resource-table-row {
      .resource-table-row-cell {
        &:nth-child(1) {
          border-left: 1px solid #eaecf0;
        }
        &:nth-last-child(1) {
          border-right: 1px solid #eaecf0;
        }
        .cell {
          line-height: 40px;
        }
      }
    }
  }
</style>
