export const dataflow = {
  banner: {
    title: '开启数据集处理新篇章',
    subtitle: 'DataFlow为数据科学家和开发者提供高效、全面的数据集处理解决方案, 为用户提供一站式的数据处理体验',
    // 预约演示
    reserveDemo: '预约演示'
  },
  feature: {
    oneStopDataProcessing: {
      title: '一站式数据处理',
      fullProcessCoverage: {
        title: '全流程覆盖',
        description: '从数据获取、清洗、标注到优化，DataFlow为您简化每个数据处理环节。'
      },
      // 闭环管理
      closedLoopManagement: {
        title: '闭环管理',
        description: '结合CSGHub，实现数据到模型的全生命周期闭环，助力持续优化。'
      },
      intelligentOperation: {
        title: '智能化操作',
        description: '让复杂的工作流变得自动化和智能化，大幅提高数据处理效率。'
      }
    },
    multiFormatAndMultiSourceDataCompatibility: {
      title: '多格式与多源数据兼容',
      multiDataSourceCompatibility: {
        title: '多数据源兼容',
        description: '支持本地文件、云端数据、网络爬虫等多种数据源接入。'
      },
      wideDataFormat: {
        title: '广泛数据格式',
        description: '无缝处理CSV、JSON、Parquet等多种格式，轻松应对不同数据需求。'
      },
      flexibleConversionTool: {
        title: '灵活转换工具',
        description: '提供高效的数据转换与读取工具，保障数据一致性和完整性。'
      }
    },
    dataCleaningAndConversion: {
      title: '数据清洗与转换',
      efficientCleaningEngine: {
        title: '高效清洗引擎',
        description: '精准去除噪音数据，确保数据质量最优化。'
      },
      complexConversionSupport: {
        title: '复杂转换支持',
        description: '通过可定制的Pipeline实现复杂数据格式转换与筛选。'
      },
      multiThreadParallelProcessing: {
        title: '多线程并行处理',
        description: '利用并行处理技术，提升数据清洗与转换速度。'
      }
    },
    intelligentAnnotationSystem: {
      title: '智能化标注系统',
      collaborativeAnnotation: {
        title: '协作式标注',
        description: '支持多人在线同时标注同一数据集，提升标注效率。'
      },
      permissionAndAuditMechanism: {
        title: '权限与审核机制',
        description: '通过角色权限与审核机制确保标注数据的准确性。'
      },
      realTimeProgressMonitoring: {
        title: '实时监控进度',
        description: '提供标注工具和仪表盘，实时掌控项目进展和标注质量。'
      }
    }
  },
  productAdvantage: {
    title: '产品优势',
    fullLifeCycleManagement: {
      title: '全生命周期管理',
      description: '与CSGHub平台的紧密集成，数据处理和模型训练的持续反馈闭环'
    },
    modularDesignAndFlexibleExtension: {
      title: '模块化设计与灵活扩展',
      description: '模块化架构，用户可以根据项目需求灵活调整工作流'
    },
    distributedComputingSupport: {
      title: '分布式计算支持',
      description: '未来支持Spark等分布式计算框架，满足海量数据处理的高性能需求'
    },
    hyperparameterOptimization: {
      title: '超参数优化',
      description: '自动优化数据处理流程，提高速度和质量'
    }
  }
}