import request from './request'

/** 具身智能设备 开始 */
// 搜索具身智能设备
export function dailyPaperList(query) {
  return request({
    url: `/internal_api/daily_papers`,
    method: 'get',
    params: query
  })
}

/** 具身智能设备 结束 */
