import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center md:flex-col px-[24px] rounded-[8px] md:px-[50px] sm:px-[20px] max-w-[1280px] m-auto bg-white" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Menu"], {
      hasSave: $setup.hasSave,
      class: "max-w-[411px] md:mb-[24px]"
    }, null, 8 /* PROPS */, ["hasSave"]),
    _createVNode($setup["ProfileEdit"], {
      onUpdateHasSave: $setup.updateHasSave,
      class: "grow"
    })
  ]))
}