import request from './request'

/** 具身智能设备 开始 */
// 创建具身智能设备
export function adminEaiDeviceCreate(data) {
  return request({
    url: `/internal_api/admin/eai_devices/`,
    method: 'post',
    data: data
  })
}

// 更新具身智能设备
export function adminEaiDeviceUpdate(data) {
  return request({
    url: `/internal_api/admin/eai_devices/${data.id}`,
    method: 'put',
    data: data
  })
}

// 查看具身智能设备
export function adminEaiDeviceDetail(id) {
  return request({
    url: `/internal_api/admin/eai_devices/${id}`,
    method: 'get',
  })
}

// 搜索具身智能设备
export function adminEaiDeviceList(query) {
  return request({
    url: `/internal_api/admin/eai_devices`,
    method: 'get',
    params: query
  })
}

/** 具身智能设备 结束 */

/** 具身智能文章 开始 */
// 创建具身智能文章
export function adminEaiArticleCreate(data) {
  return request({
    url: `/internal_api/admin/eai_articles/`,
    method: 'post',
    data: data
  })
}

// 更新具身智能文章
export function adminEaiArticleUpdate(data) {
  return request({
    url: `/internal_api/admin/eai_articles/${data.id}`,
    method: 'put',
    data: data
  })
}

// 查看具身智能文章
export function adminEaiArticleShow(id) {
  return request({
    url: `/internal_api/admin/eai_articles/${id}`,
    method: 'get',
  })
}

// 搜索具身智能文章
export function adminEaiArticleList(query) {
  return request({
    url: `/internal_api/admin/eai_articles`,
    method: 'get',
    params: query
  })
}

// 查看具身智能文章
export function adminEaiArticleDetail(id) {
  return request({
    url: `/internal_api/admin/eai_articles/${id}`,
    method: 'get',
  })
}
/** 具身智能文章 结束 */