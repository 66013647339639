<template>
  <div class="max-w-[405px] w-full flex flex-col gap-[12px] md:max-w-full">
    <a :href="`/eai_articles/${article.uuid}`">
      <img :src="article.cover_url" class="max-w-[405px] w-full rounded-[16px] h-[270px] object-cover">
    </a>
    <div class="flex flex-col gap-[8px]">
      <a :href="`/eai_articles/${article.uuid}`">
        <div class="flex gap-[16px] justify-between items-center">
          <div class="text-[var(--Gray-900)] text-[24px] font-[500] leading-[32px] md:text-[20px] md:leading-[30px] line-clamp line-clamp-1">{{ article.title }}</div>
          <div class="w-[24px] flex">
            <el-icon :size="24"><TopRight /></el-icon>
          </div>
        </div>
      </a>
      <div class="line-clamp line-clamp-2 text-[var(--Gray-500)] text-[16px] font-[400] leading-[24px] md:text-[14px] md:leading-[20px]">
        {{ article.description }}
      </div>
      <div class="flex justify-between text-[var(--Gray-500)] text-[16px] font-[400] leading-[24px] md:text-[12px] md:leading-[18px]">
        <div>
          {{ dayjs(article.created_at).format("YYYY.M.D HH:mm") }}
        </div>
        <div class="flex gap-[4px] items-center">
          <SvgIcon name="eye" width="24" height="24" />
          <span>{{ article.view_count }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted } from "vue";
  import dayjs from 'dayjs'

  const props = defineProps({
    article: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        cover: "",
        view_count: 0,
        content: "",
      }),
    }
  });

  const article = ref(props.article);
</script>