<template>
  <el-card>
    <template #header>
      <div class="flex items-center justify-between mb-[20px]">
        <h3 class="text-[20x] font-[500]">Mirrors</h3>
        <div class="flex justify-end gap-2">
          <router-link to="/new_admin/mirrors/new">
            <el-button type="primary">创建</el-button>
          </router-link>
        </div>
      </div>
    </template>

    <el-table
      :stripe="true"
      :data="mirrors"
    >
      <el-table-column
        prop="source_url"
        label="Source Url"
      />
      <el-table-column
        label="Mirror Source Name"
        scoped-slot="default"
      >
        <template #default="scope">
          {{ mirrors[scope.$index].mirror_source.source_name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="local_repo_path"
        label="Local Repo Path"
      />
      <!-- <el-table-column label="操作" fixed="right">
        <template #default="scope">
          <div class="flex gap-2">
            <router-link
              :to="`/new_admin/mirrors/${mirrors[scope.$index].id}`"
            >
              <el-button
                size="small"
              >
                详情
              </el-button>
            </router-link>
            <router-link
              :to="`/new_admin/mirrors/${mirrors[scope.$index].id}/edit`"
            >
              <el-button
                size="small"
              >
                编辑
              </el-button>
            </router-link>
            <router-link
              :to="`/new_admin/mirrors/${mirrors[scope.$index].id}/delete`"
            >
              <el-button
                size="small"
              >
                删除
              </el-button>
            </router-link>
          </div>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      v-model:current-page="page"
      :page-size="per"
      layout="prev, pager, next"
      :total="total"
      @current-change="fetchMirrors"
      class="my-[52px] flex justify-center"
    />
  </el-card>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { ElMessage } from 'element-plus'
  import useFetchApi from "../../../packs/useFetchApi"

  const mirrors = ref([]);
  const keyword = ref("");
  const page = ref(1);
  const per = ref(10);
  const total = ref(0);

  const fetchMirrors = async (current) => {
    const { data, error } = await useFetchApi(
      `/mirrors?&sort=trending&page=${ current || page.value }&per=${per.value}&search=${keyword.value}`
    ).json()

    if (error.value) {
      ElMessage.error('Failed to fetch mirrors')
    } else {
      const body = data.value
      mirrors.value = body.data.data
      total.value = body.data.total
    }
  }

  onMounted(() => {
    fetchMirrors();
  });
</script>