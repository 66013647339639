import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c496e4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "m-auto w-[480px] md:w-full md:px-[20px]" }
const _hoisted_3 = { class: "py-[96px] text-center" }
const _hoisted_4 = { class: "text-[#223B99] text-[16px] font-[500] leading-[24px]" }
const _hoisted_5 = { class: "mt-[12px] text-[48px] font-[500] leading-[60px] tracking-[-0.96px]" }
const _hoisted_6 = { class: "mt-[24px] text-[20px] font-[300] leading-[30px]" }
const _hoisted_7 = { class: "el-upload__text" }
const _hoisted_8 = { class: "text-[#223B99] font-[500]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_upload_filled = _resolveComponent("upload-filled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('dailyPaper.paper')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('dailyPaper.goodpaper')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('dailyPaper.recommendUser')), 1 /* TEXT */),
        _createVNode(_component_el_form, {
          ref: "dataForm",
          model: $data.dataForm,
          rules: $data.rules,
          "label-position": "top",
          class: "mt-[48px] text-left"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.title'),
              prop: "title"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $data.dataForm.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.dataForm.title) = $event)),
                  placeholder: _ctx.$t('dailyPaper.paperTitle')
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.img'),
              prop: "cover_image"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_upload, {
                  class: _normalizeClass(`w-full ${this.showUpload ? 'h-[229px]' : 'hide'}`),
                  limit: 1,
                  "file-list": $data.dataForm.cover_image,
                  "onUpdate:fileList": _cache[1] || (_cache[1] = $event => (($data.dataForm.cover_image) = $event)),
                  drag: "",
                  "list-type": "picture-card",
                  headers: { 'X-CSRF-TOKEN': $data.csrf_token },
                  accept: "image/png, image/jpeg, image/gif, image/svg+xml",
                  data: {namespace: 'daily_paper'},
                  action: "/internal_api/upload",
                  "on-progress": $options.handleUploadProgress,
                  "on-remove": $options.handleRemoveImage,
                  "on-success": $options.handleUploadSuccess
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_upload_filled)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("p", null, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('dailyPaper.click')), 1 /* TEXT */),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('dailyPaper.drug')), 1 /* TEXT */)
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('dailyPaper.type')), 1 /* TEXT */)
                      ])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["class", "file-list", "headers", "on-progress", "on-remove", "on-success"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.link'),
              prop: "article_link"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $data.dataForm.article_link,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.dataForm.article_link) = $event)),
                  placeholder: _ctx.$t('dailyPaper.paperLink')
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.publishTime'),
              prop: "published_at"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: $data.dataForm.published_at,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.dataForm.published_at) = $event)),
                  type: "date",
                  style: {"width":"100%"},
                  placeholder: _ctx.$t('dailyPaper.date')
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.pdfLink'),
              prop: "pdf_link"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $data.dataForm.pdf_link,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.dataForm.pdf_link) = $event)),
                  placeholder: _ctx.$t('dailyPaper.recommendPdfLink')
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.recommendation'),
              prop: "recommendation"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  rows: 5,
                  type: "textarea",
                  modelValue: $data.dataForm.recommendation,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.dataForm.recommendation) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.paperRemark'),
              prop: "description"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  rows: 5,
                  type: "textarea",
                  modelValue: $data.dataForm.description,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.dataForm.description) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.videoLin'),
              prop: "video_link"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $data.dataForm.video_link,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.dataForm.video_link) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.paperModel'),
              prop: "model_links"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  rows: 5,
                  type: "textarea",
                  modelValue: $data.dataForm.model_links,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.dataForm.model_links) = $event)),
                  placeholder: _ctx.$t('dailyPaper.linkDesc')
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('dailyPaper.paperDataset'),
              prop: "dataset_links"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  rows: 5,
                  type: "textarea",
                  modelValue: $data.dataForm.dataset_links,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.dataForm.dataset_links) = $event)),
                  placeholder: _ctx.$t('dailyPaper.linkDesc')
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: "w-full !text-center !h-[48px] !text-[16px] btn btn-primary",
                  onClick: $options.handleSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('dailyPaper.submit')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ])
    ])
  ]))
}