export const dataflow = {
  banner: {
    title: 'Opening a new chapter in dataset processing',
    subtitle: 'DataFlow offers an efficient all-in-one data processing solution for scientists and developers , providing a seamless end-to-end experience',
    // 预约演示
    reserveDemo: 'Book a demo'
  },
  feature: {
    oneStopDataProcessing: {
      title: 'All-in-One Data Processing',
      fullProcessCoverage: {
        title: 'Full process coverage',
        description: ' From data acquisition, cleaning, and labeling to optimization, DataFlow simplifies every step of your data workflow.'
      },
      // 闭环管理
      closedLoopManagement: {
        title: 'Life-cycle management',
        description: 'Integrated with CSGHub, it ensures a complete lifecycle from data to model, driving continuous optimization.'
      },
      intelligentOperation: {
        title: 'Smart automation',
        description: 'Automate complex workflows with intelligent tools, greatly enhancing data processing efficiency.'
      }
    },
    multiFormatAndMultiSourceDataCompatibility: {
      title: 'Multi-Format and Multi-Source Compatibility',
      multiDataSourceCompatibility: {
        title: 'Multiple source compatibility',
        description: 'Supports various data sources including local files, cloud data, and web crawlers.'
      },
      wideDataFormat: {
        title: 'Wide range of formats',
        description: 'Seamlessly handles formats like CSV, JSON, and Parquet to meet diverse data needs.'
      },
      flexibleConversionTool: {
        title: 'Flexible conversion tools',
        description: 'Provides efficient data conversion and reading tools, ensuring consistency and integrity.'
      }
    },
    dataCleaningAndConversion: {
      title: 'Data Cleaning and Transformation',
      efficientCleaningEngine: {
        title: 'Efficient cleaning engine',
        description: 'Precisely reduce noisy data, ensuring optimal data quality.'
      },
      complexConversionSupport: {
        title: 'Complex transformation support',
        description: 'Customizable pipelines enable complex data format conversions and filtering.'
      },
      multiThreadParallelProcessing: {
        title: 'Multithreaded parallel processing',
        description: 'Leverages parallel processing to speed up data cleaning and transformation.'
      }
    },
    intelligentAnnotationSystem: {
      title: 'Intelligent Annotation System',
      collaborativeAnnotation: {
        title: 'Collaborative annotation',
        description: 'Supports collaborative annotation by multiple users, improving efficiency.'
      },
      permissionAndAuditMechanism: {
        title: 'Roles and review mechanism',
        description: 'Ensures annotation accuracy with role-based permissions and review processes.'
      },
      realTimeProgressMonitoring: {
        title: 'Real-time progress tracking',
        description: 'Monitoring project progress and data quality in real time by tools and dashboards.'
      }
    }
  },
  productAdvantage: {
    title: 'Product Advantages',
    fullLifeCycleManagement: {
      title: 'Full lifecycle management',
      description: 'Seamless integration with the CSGHub platform enables a continuous feedback loop between data processing and model training.'
    },
    modularDesignAndFlexibleExtension: {
      title: 'Flexible expansion by modular design',
      description: 'Modular architecture allows users to adjust workflows based on project requirements.'
    },
    distributedComputingSupport: {
      title: 'Distributed computing support',
      description: ' Will support distributed computing frameworks (eg, Spark) to meet high-performance data processing needs.'
    },
    hyperparameterOptimization: {
      title: 'Hyperparameter optimization',
      description: 'Automatically optimizes data processing workflows to enhance speed and quality.'
    }
  }
}