<template>
  <div class="w-full bg-[var(--Gray-25)] border-b border-[#EAECF0] md:px-[20px]">
    <div class="max-w-[1280px] w-full m-auto pt-[56px] pb-[40px] md:py-[32px]">
      <div class="flex flex-col gap-[16px]">
        <div class="text-[var(--Gray-900)] text-[32px] font-[500] leading-32px md:text-[20px] md:leading-[30px]">
          {{ article.title }}
        </div>
        <div class="text-[var(--Gray-700)] text-[16px] font-[400] leading-[24px]">
            {{ article.description }}
        </div>
        <div class="flex justify-between text-[var(--Gray-500)] text-[14px] font-[400] leading-[24px]">
          <div class="flex gap-[24px]">
            <div class="flex gap-[4px] items-center">
              <SvgIcon name="eye" width="24" height="24" />
              <span>{{ article.view_count }}</span>
            </div>
            <div>
              {{ dayjs(article.created_at).format("YYYY.M.D HH:mm") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 正文 -->
  <div class="max-w-[1280px] w-full m-auto md:px-[20px]">
    <div class="flex js-toc-content">
      <div class="max-w-[963px] w-full pt-[68px] pr-[120px] lg:max-w-[730px] lg:pr-[20px] md:pt-[40px] md:px-0 border-r border-[#EAECF0] md:border-0 flex flex-col">
        <div class="ck-content" v-html="article.content"></div>

        <!-- 关联文章 -->
        <div class="max-w-[1280px] w-full m-auto pt-[60px] md:px-[20px] md:pt-[40px]" v-if="article.daily_paper_ids.length > 0">
          <h2 class="text-[var(--Gray-900)] text-[24px] font-[500] leading-[32px] md:text-[20px] md:font-[600] md:leading-[30px]">
            {{ t('eaiArticle.dailyPapers') }}
          </h2>
          <div class="grid grid-cols-2 md:grid-cols-1 gap-[32px] pt-[33px] md:pt-[20px]">
            <template v-for="item in dailyPapers" :key="item.id">
              <DailyPaperCard :dailyPaper="item" />
            </template>
          </div>
        </div>

        <!-- 关联设备 -->
        <div class="max-w-[1280px] w-full m-auto pt-[60px] md:px-[20px] md:pt-[40px]" v-if="article.eai_device_ids.length > 0">
          <h2 class="text-[var(--Gray-900)] text-[24px] font-[500] leading-[32px] md:text-[20px] md:font-[600] md:leading-[30px]">
            {{ t('eaiArticle.relatedDevices') }}
          </h2>
          <div class="grid grid-cols-1 gap-[32px] pt-[33px] md:pt-[20px]">
            <template v-for="item in relatedDevices" :key="item.id">
              <EaiDeviceDetailCard :device="item" />
            </template>
          </div>
        </div>

        <!-- 代码仓库 -->
        <div class="max-w-[1280px] w-full m-auto pt-[60px] md:px-[20px] md:pt-[40px]" v-if="article.code_paths.length > 0">
          <h2 class="text-[var(--Gray-900)] text-[24px] font-[500] leading-[32px] md:text-[20px] md:font-[600] md:leading-[30px]">
            {{ t('eaiArticle.relatedCodes') }}
          </h2>
          <div class="grid grid-cols-2 md:grid-cols-1 gap-[32px] pt-[20px]">
            <template v-for="item in codes" :key="item.path">
              <CodeDetailCard :code="item" />
            </template>
          </div>
        </div>
      </div>

      <div class="pt-[68px] w-full flex-1 md:hidden min-w-[179px]">
        <div class="sticky top-[144px]">
          <div class="toc js-toc"></div>
        </div>
      </div>

    </div>
  </div>

  <div class="pb-[40px]"></div>
  <el-dialog
    v-model="dialogImagePreview"
    width="90%"
    style="--el-dialog-bg-color: transparent; --el-dialog-box-shadow: unset">
    <img :src="dialogImageUrl" alt="" style="width: 100%">
  </el-dialog>
</template>

<script setup>
  import { ref, onMounted, watch, nextTick } from "vue";
  import { useI18n } from 'vue-i18n'
  import { eaiArticleDetail, eaiDeviceList } from "../../api/csrf/eai"
  import { dailyPaperList } from "../../api/csrf/daily_paper"
  import { codeDetail } from "../../api/jwt/code"
  import CodeDetailCard from "./CodeDetailCard.vue";
  import DailyPaperCard from "./DailyPaperCard.vue";
  import EaiDeviceDetailCard from "./EaiDeviceDetailCard.vue";
  import { ElMessage } from 'element-plus'
  import dayjs from 'dayjs'
	import tocbot from 'tocbot'
  import hljs from 'highlight.js'

  const { t } = useI18n()
  const article = ref({
    daily_paper_ids: [],
    eai_device_ids: [],
    code_paths: [],
  });
  const dailyPapers = ref([]);
  const relatedDevices = ref([]);
  const codes = ref([]);

  const dialogImagePreview = ref(false);
	const dialogImageUrl = ref('');


  onMounted(() => {
    fetchEaiArticle();
  });


  const fetchEaiArticle = () => {
    const uuid = window.location.pathname.split("/").pop();
    eaiArticleDetail(uuid).then((res) => {
      article.value = res.data;
      const { daily_paper_ids, eai_device_ids } = res.data
      if (daily_paper_ids.length > 0) {
        fetchDailyPapers();
      }
      if (eai_device_ids.length > 0) {
        fetchRelatedDevices();
      }
      fetchCodes();
      nextTick(() => {
        generateTableOfContent();
        imagePreview();
        highlightLanguage();
      });
    }).catch((err) => {
      ElMessage.error(err.message);
    });
  };

  const fetchDailyPapers = () => {
    dailyPaperList({ids: article.value.daily_paper_ids}).then((res) => {
      dailyPapers.value = res.data;
    }).catch((err) => {
      ElMessage.error(err.message);
    });
  };

  const fetchRelatedDevices = () => {
    eaiDeviceList({ids: article.value.eai_device_ids}).then((res) => {
      relatedDevices.value = res.data;
    }).catch((err) => {
      ElMessage.error(err.message);
    });
  };

  const fetchCodes = () => {
    codes.value = []
    article.value.code_paths.forEach((path) => {
      codeDetail(path).then((res) => {
        codes.value.push(res.data);
      }).catch((err) => {
        ElMessage.error(err.msg);
      });
    })
  };


	const generateToken = () => {
		return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
	};

	const generateTableOfContent = () => {
		document.querySelectorAll('.js-toc-content h2, .js-toc-content h3, .js-toc-content h4, .js-toc-content h5, .js-toc-content h6').forEach((heading) => {
			heading.id = generateToken();
		});

		const options = {
			tocSelector: '.js-toc',
			contentSelector: '.js-toc-content',
			headingSelector: 'h2, h3, h4, h5',
		}

		tocbot.init(options)
	};

	const imagePreview = () => {
		const images = document.querySelectorAll('.js-toc-content img');
		images.forEach((image) => {
			image.addEventListener('click', () => {
				dialogImageUrl.value = image.src;
				dialogImagePreview.value = true;
			});
		});
	};

	const highlightLanguage = () => {
		const codes = document.querySelectorAll('.js-toc-content pre code');
		codes.forEach((code) => {
			hljs.highlightElement(code);
		});
	};
</script>