export const languages = {
  Chinese: '中文',
  English: '英文',
  French: '法文',
  German: '德文',
  Italian: '意大利文',
  Japanese: '日文',
  Korean: '韩文',
  Spanish: '西班牙文',
  Portuguese: '葡萄牙文',
  Russian: '俄文',
  Arabic: '阿拉伯文',
  ChineseTraditional: '繁体中文',
  ChineseSimplified: '简体中文',
  Hindi: '印地文',
  Indonesian: '印尼文',
  Malay: '马来文',
  Vietnamese: '越南文',
  Turkish: '土耳其文',
  Dutch: '荷兰文',
  Polish: '波兰文',
  Swedish: '瑞典文'
}