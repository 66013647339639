<template>
  <el-card>
    <el-descriptions
      title="具身智能硬件"
      :column="2"
      border
    >
      <el-descriptions-item label="标题">{{ eaiDevice.title }}</el-descriptions-item>
      <el-descriptions-item label="链接">
        <a :href="eaiDevice.link" target="_blank">{{ eaiDevice.link }}</a>
      </el-descriptions-item>
      <el-descriptions-item label="描述" :span="2">{{ eaiDevice.description }}</el-descriptions-item>
      <el-descriptions-item label="封面">
        <img :src="eaiDevice.cover_url" alt="cover" class="h-[200px] object-cover" />
      </el-descriptions-item>
    </el-descriptions>
    
    <template #footer>
      <div class="card-footer">
        <router-link :to="`/new_admin/eai_articles/${eaiDevice.id}/edit`">
          <el-button class="w-[100px]">编辑</el-button>
        </router-link>
      </div>
    </template>
  </el-card>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { useRouter } from 'vue-router'
  import { adminEaiDeviceDetail } from "../../../api/csrf/admin_eai"

  const router = useRouter();
  
  const eaiDevice = ref({});

  onMounted(() => {
    const id = router.currentRoute.value.params.id
    adminEaiDeviceDetail(id).then((res) => {
      const { data } = res;
      eaiDevice.value = data;

    });
  });
</script>