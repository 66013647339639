import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full mb-[37px]" }
const _hoisted_2 = { class: "items-center pl-3" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "text-[14px] font-[300] leading-[20px] text-[#475467]" }
const _hoisted_5 = { class: "items-center pl-3" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = {
  key: 0,
  class: "text-[14px] font-[300] leading-[20px] text-[#475467]"
}
const _hoisted_8 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_9 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_10 = { class: "text-[14px] font-[400] leading-[20px] text-[#475467]" }
const _hoisted_11 = { class: "flex justify-center text-[12px] font-[400] leading-[18px] text-[#475467]" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "text-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      class: "billing-table",
      "header-row-class-name": "billing-table-header-row",
      "header-cell-class-name": "billing-table-header-cell",
      "row-class-name": "billing-table-row",
      "cell-class-name": "billing-table-row-cell",
      data: $props.billings,
      stripe: "",
      style: {"width":"100%"}
    }, {
      empty: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('all.noData')), 1 /* TEXT */)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('billing.instanceName'),
          "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(scope.row.repo_path.split('/')[1]), 1 /* TEXT */)
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('billing.instanceID'),
          "label-class-name": "indent-3 text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (!!scope.row.instance_name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, " #" + _toDisplayString(scope.row.instance_name), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        ($props.type !== 'starship')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              label: _ctx.$t('billing.createTime'),
              "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_8, _toDisplayString($setup.formatDate(scope.row.created_at)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_el_table_column, {
          label: $props.type === 'starship'? _ctx.$t('billing.tokenNum') : _ctx.$t('billing.usageTime'),
          "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_9, _toDisplayString(scope.row.consumption), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('billing.cost'),
          "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_10, _toDisplayString((Math.abs(scope.row.value)/100.0).toFixed(2)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        ($props.type !== 'starship')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 1,
              label: _ctx.$t('billing.status'),
              "label-class-name": "text-[12px] font-[400] leading-[18px] text-[#475467]",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode($setup["BillingStatus"], {
                    status: scope.row.status
                  }, null, 8 /* PROPS */, ["status"])
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        ($props.type !== 'starship')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 2,
              width: "100",
              align: "center",
              fixed: "right"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("a", {
                  href: $setup.detailLink(scope.row),
                  class: "flex gap-4 justify-end pr-4 text-[#223B99]"
                }, _toDisplayString(_ctx.$t('billing.details')), 9 /* TEXT, PROPS */, _hoisted_12)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data"])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}