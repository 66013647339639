import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[405px] w-full flex flex-col md:max-w-full rounded-[16px] border border-[var(--Gray-200)]" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-col gap-[8px] bg-[var(--Gray-25)] rounded-[0_0_16px_16px] p-[20px]" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex gap-[16px] justify-between items-center" }
const _hoisted_7 = { class: "text-[var(--Gray-900)] text-[24px] font-[500] leading-[32px] md:text-[20px] md:leading-[30px] line-clamp line-clamp-1" }
const _hoisted_8 = { class: "w-[24px] flex" }
const _hoisted_9 = { class: "line-clamp line-clamp-2 text-[var(--Gray-500)] text-[16px] font-[400] leading-[24px] md:text-[14px] md:leading-[20px]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TopRight = _resolveComponent("TopRight")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: `/daily_papers/${$setup.dailyPaper.uuid}`
    }, [
      _createElementVNode("img", {
        src: $setup.dailyPaper.cover_url,
        class: "max-w-[405px] w-full h-[270px] rounded-[16px_16px_0_0] object-cover"
      }, null, 8 /* PROPS */, _hoisted_3)
    ], 8 /* PROPS */, _hoisted_2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: `/daily_papers/${$setup.dailyPaper.uuid}`
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.dailyPaper.title), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_icon, { size: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_TopRight)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ], 8 /* PROPS */, _hoisted_5),
      _createElementVNode("div", _hoisted_9, _toDisplayString($setup.dailyPaper.description), 1 /* TEXT */)
    ])
  ]))
}